import React from "react";
import {
  useHMSStore,
  useHMSActions,
  selectIsLocalVideoPluginPresent,
} from "@100mslive/react-sdk";
import { HMSVirtualBackgroundPlugin } from "@100mslive/hms-virtual-background";
import { Button } from "antd";
import VB from "../../assets/virtualBackgroundInverted.jpg";
import { BsImage } from "react-icons/bs";

function VirtualBackground() {
  const hmsActions = useHMSActions();

  const img = document.createElement("img");
  img.alt = "VB";
  img.src = VB;
  // background : {'blur' | image | 'none'}
  const virtualBackground = new HMSVirtualBackgroundPlugin(img || "blur");

  const pluginSupport =
    hmsActions.validateVideoPluginSupport(virtualBackground);
  if (pluginSupport.isSupported) {
    console.log("Platform is supported");
  } else {
    const err = pluginSupport.errMsg;
    console.error(err);
  }

  const isVirtualBackgroundEnabled = useHMSStore(
    selectIsLocalVideoPluginPresent(virtualBackground.getName())
  );

  const toggleVB = async () => {
    try {
      if (!isVirtualBackgroundEnabled) {
        // Recommended value
        const pluginFrameRate = 15;
        await hmsActions.addPluginToVideoTrack(
          virtualBackground,
          pluginFrameRate
        );
      } else {
        await hmsActions.removePluginFromVideoTrack(virtualBackground);
      }
    } catch (err) {
      console.log(
        "virtual background failure - ",
        isVirtualBackgroundEnabled,
        err
      );
    }
  };

  return (
    pluginSupport.isSupported && (
      <Button
        onClick={toggleVB}
        className="inline-flex items-center justify-center"
        type="secondary"
        shape="circle"
        size="large"
      >
        <BsImage />{" "}
      </Button>
    )
  );
}

export { VirtualBackground };
