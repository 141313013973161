import React, { useEffect, useState } from "react";
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSNotifications,
  useHMSStore,
} from "@100mslive/react-sdk";
import { getBookedSessions } from "../../redux/services/confirm-slots/getBookedSessions";
import { MeetingJoinForm, MeetingRoom } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Select } from "antd";
import { setCoachingSessionStatus } from "../../redux/services";
import { toast } from "react-toastify";

export const VideoCall = () => {
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const [roomEnded, setRoomEnded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [coachingSessionDetails, setCoachingSessionDetails] = useState(null);
  const { isLoggedIn, user: coach } = useSelector((state) => state.auth);
  const notification = useHMSNotifications();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!notification) {
      return;
    }
    switch (notification.type) {
      case "ROOM_ENDED":
        setRoomEnded(true);
        break;
      case "NEW_MESSAGE":
        if (notification.data.message === "TIME_EXTENDED") {
          toast.info("Session duration extended by 5 minutes");
        }
        if (notification.data.message === "SESSION_GOING_TO_CLOSED") {
          toast.info("Session will be closed in next 2 minutes");
        }
        break;
      default:
        break;
    }
  }, [notification]);

  const confirmStatus = () => {
    dispatch(
      setCoachingSessionStatus({
        sessionId: location?.state?.id,
        status: selectedStatus,
        getBookedSessions,
        coachId: coach.id,
      })
    );
    navigate("/confirmed-sessions");
  };

  useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected]);

  if (roomEnded && isLoggedIn) {
    return (
      <section className="max-w-[21.8rem] mx-auto px-2">
        <div className="flex py-4 flex-col items-center gap-2">
          <Form onFinish={() => confirmStatus()}>
            <Form.Item
							name="status"
              rules={[{ required: true, message: "Please select status" }]}
              required
            >
              <Select
                className="w-full text-center"
                placeholder="Update Coaching Session Status"
                onChange={(value) => setSelectedStatus(value)}
                options={[
                  {
                    value: "Session completed",
                    label: "Session completed",
                  },
                  {
                    value: "Coachee no show",
                    label: "Coachee no show",
                  },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section>
    );
  } else {
    return isConnected ? (
      <MeetingRoom
        setRoomEnded={setRoomEnded}
        coachingSessionDetails={coachingSessionDetails}
      />
    ) : (
      <MeetingJoinForm setCoachingSessionDetails={setCoachingSessionDetails} />
    );
  }
};
