import { createSlice } from "@reduxjs/toolkit";
import { login, forgotPassword, resetPassword } from "../services";
import { toast } from "react-toastify";

// removing previous values -> coach data update on login
localStorage.removeItem("m-coach");
localStorage.removeItem("m-c-token");
localStorage.removeItem("coach");
localStorage.removeItem("token");

const initialState = {
  user: JSON.parse(localStorage.getItem("coach_m")) ?? null,
  token: localStorage.getItem("token_m") ?? null,
  isLoggedIn: Boolean(localStorage.getItem("token_m")),
  fetchingUser: "idle",
  loginError: null,
  forgotPasswordSendingEmailStatus: "idle",
  forgotPasswordSendingEmailResponseError: "",
  resetPasswordConfirmStatus: "idle",
  resetPasswordStatus: null,
  resetPasswordError: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
      state.fetchingUser = "idle";
      state.loginError = null;
      localStorage.removeItem("coach_m");
      localStorage.removeItem("token_m");
    },
    resetForgotPasswordState: (state) => {
      state.forgotPasswordSendingEmailStatus = "idle";
      state.forgotPasswordSendingEmailResponseError = "";
    },
  },
  extraReducers(builder) {
    builder
      // login handlers
      .addCase(login.pending, (state) => {
        state.fetchingUser = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.fetchingUser = "fulfilled";
        state.user = {
          username: action.payload.username,
          id: action.payload.id,
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          middleName: action.payload.middle_name,
          meet_link: action.payload.meet_link,
        };
        state.token = action.payload.token;
        state.isLoggedIn = true;
        state.loginError = null;
        localStorage.setItem(
          "coach_m",
          JSON.stringify({
            username: action.payload.username,
            id: action.payload.id,
            firstName: action.payload.first_name,
            lastName: action.payload.last_name,
            middleName: action.payload.middle_name,
            meet_link: action.payload.meet_link,
          })
        );
        localStorage.setItem("token_m", action.payload.token);
      })
      .addCase(login.rejected, (state, action) => {
        state.fetchingUser = "rejected";
        state.loginError = action.payload.response.data.reason;
        toast.error(action.payload.response.data.reason, { autoClose: 5000 });
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.forgotPasswordSendingEmailStatus = "loading";
        state.forgotPasswordSendingEmailResponseError = "";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordSendingEmailStatus = "success";
        state.forgotPasswordSendingEmailResponseError = "";
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordSendingEmailStatus = "failed";
        if (action.payload.response.status === 400) {
          state.forgotPasswordSendingEmailResponseError =
            action.payload.response.data.email[0];
        }
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.resetPasswordConfirmStatus = "loading";
        state.resetPasswordError = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPasswordConfirmStatus = "success";
        state.resetPasswordError = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordConfirmStatus = "failed";
        state.resetPasswordStatus = action.payload.response.status;
        state.resetPasswordError = action.payload.response.data;
      });
  },
});

export const { logout, resetForgotPasswordState } = authSlice.actions;

export default authSlice.reducer;
