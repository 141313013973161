import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const setCoachingSessionStatus = createAsyncThunk(
  "setCoachingSessionStatus",
  async (
    { status, sessionId, coachId, getBookedSessions },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}update-session-status/${sessionId}/`,
        { status: status }
      );
      if (res.status === 200) {
        dispatch(getBookedSessions(coachId));
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
