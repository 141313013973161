import { Collapse } from "antd";
import React from "react";

const items = [
  {
    key: "1",
    label: "Provide Slots",
    children: (
      <>
        <p className="p-2">
          {" "}
          You can provide the slots for the requests you receive. Go to - Slot
          Requests → New → pick your available slots and submit with the “Click
          here” button. Once you provide your available slots, you cannot
          provide more or new slots for the same request.
        </p>
        <iframe
          src="https://www.veed.io/embed/4e1f639f-8c78-4053-94ea-5dea9a56a8f0"
          width="744"
          height="504"
          frameborder="0"
          title="Provide Multiple Slots"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
        <p className="p-2">
          {" "}
          In case you wish to make changes please reach out to{" "}
          <a
            className="text-sky-600 underline"
            href="mailto:pmocoaching@meeraq.com"
          >
            pmocoaching@meeraq.com
          </a>
        </p>
      </>
    ),
  },
  {
    key: "2",
    label: "Join session",
    children: (
      <>
        <p className="p-2">
          {" "}
          Go to Confirmed Sessions → Upcoming Sessions tab . Here you will be
          able to join the session 15 mins before the session start time.
        </p>{" "}
        <iframe
          src="https://www.veed.io/embed/6534a731-f78b-4e9b-8b47-2bfe2993c9bc"
          width="744"
          height="504"
          frameborder="0"
          title="Join Session"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>{" "}
        <p className="p-2">
          {" "}
          In case you wish to make changes please reach out to{" "}
          <a
            className="text-sky-600 underline"
            href="mailto:pmocoaching@meeraq.com"
          >
            pmocoaching@meeraq.com
          </a>
        </p>
      </>
    ),
  },
  {
    key: "3",
    label: "Delete  Slots",
    children: (
      <>
        <p className="p-2">
          {" "}
          You can only delete the slots you have provided. You cannot reschedule
          the slot or delete the confirmed slots.{" "}
        </p>
        <iframe
          src="https://www.veed.io/embed/45119183-059d-4fd6-89d0-37efaec57639"
          width="744"
          height="504"
          frameborder="0"
          title="Delete Slots"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
        <p className="p-2">
          In case you need assistance for the same please reach out to{" "}
          <a
            className="text-sky-600 underline"
            href="mailto:pmocoaching@meeraq.com"
          >
            pmocoaching@meeraq.com
          </a>
        </p>
      </>
    ),
  },
];

const { Panel } = Collapse;

export const Help = () => {
  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <Collapse accordion={true}>
        {items.map((item) => (
          <Panel header={item.label} key={item.key}>
            {item.video}
            {item.children}
          </Panel>
        ))}
      </Collapse>
    </main>
  );
};
