import { MdAdd } from "react-icons/md";
import { SlotWrapper } from "../slot-wrapper/SlotWrapper";

const DayWrapper = ({ day, slotData, dispatchSlotData }) => {
  return (
    <div className="flex gap-8 md:gap-12 items-start border-b-[1px] py-4 ">
      <span className="p-2 inline-block w-20 font-semibold">{day}:</span>
      <div className="flex flex-col gap-4 grow text-center">
        {slotData[day].length > 0 ? (
          slotData[day].map((slot) => (
            <SlotWrapper
              key={slot.id}
              day={day}
              slot={slot}
              dispatchSlotData={dispatchSlotData}
            />
          ))
        ) : (
          <p className="p-2 text-gray-300">Unavailbale</p>
        )}
      </div>
      <button
        onClick={() => dispatchSlotData({ type: "ADD_SLOT", payload: day })}
        className="p-2 text-2xl text-gray-600"
      >
        <MdAdd />
      </button>
    </div>
  );
};

export { DayWrapper };
