import {
  selectAudioTrackByPeerID,
  selectVideoTrackByPeerID,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useRef, useEffect } from "react";
import { MdMicOff } from "react-icons/md";

function Peer({ peer }) {
  const videoRef = useRef(null);
  const hmsActions = useHMSActions();
  const videoTrack = useHMSStore(selectVideoTrackByPeerID(peer.id));
  const audioTrack = useHMSStore(selectAudioTrackByPeerID(peer.id));

  useEffect(() => {
    if (videoRef.current && videoTrack) {
      if (videoTrack.enabled) {
        hmsActions.attachVideo(videoTrack.id, videoRef.current);
      } else {
        hmsActions.detachVideo(videoTrack.id, videoRef.current);
      }
    }
  }, [videoTrack, hmsActions]);

  return (
    <div
      className={`min-w-[15.625rem] max-w-[18.75rem] sm:max-w-[43.75rem] w-[40%] relative`}
    >
      {videoTrack?.enabled ? (
        <video
          style={{ aspectRatio: 4 / 3, backgroundColor: "gray" }}
          className="w-full rounded-[15px] overflow-hidden -scale-x-100"
          ref={videoRef}
          autoPlay
          muted
          playsInline
        />
      ) : (
        <div className="aspect-[4/3] md:w-auto flex items-center justify-center bg-[white] border-[#A63295] border-[2px] rounded-[15px]">
          <p className="min-w-[2.4em] h-auto text-3xl aspect-square p-4 text-center text-[#A63295] rounded-full bg-gray-200">
            {peer.name.split("")[0].toUpperCase()}
          </p>
        </div>
      )}

      <div className="text-center capitalize">
        {peer.name} {peer.isLocal ? "(You)" : ""}
      </div>
      {!peer.isLocal && audioTrack?.enabled && (
        <span className="absolute top-2 right-2 border rounded-full p-2">
          <MdMicOff />
        </span>
      )}
    </div>
  );
}

export { Peer };
