import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RequestRow } from "../request-row/RequestRow";

const RequestsData = ({ requestType }) => {
  const [isExpanded, setIsExpanded] = useState(null);
  const { requests } = useSelector((state) => state.confirmSlots);
  const { fetchingAdminRequest } = useSelector((state) => state.adminRequest);
  const requestIds = Object.keys(requests);

  return (
    <>
      <table className="w-[95%] mx-auto mb-6 border mt-2 p-2">
        <thead className="border bg-slate-100">
          <tr className="py-2">
            <th className="py-2 border border-r-slate-300">Request Name </th>
            <th className="py-2 border border-r-slate-300">Expiry Date</th>
            <th className="py-2">Slots</th>
          </tr>
        </thead>
        <tbody>
          {fetchingAdminRequest === "loading" ? (
            <tr>
              <td colSpan={3} className="">
                <em className="">Loading...</em>
              </td>
            </tr>
          ) : requestIds.length > 0 ? (
            requestIds.map((requestId) => (
              <RequestRow
                key={requestId}
                requestType={requestType}
                requestId={requestId}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
            ))
          ) : (
            <tr>
              <td colSpan={3} className="text-center text-xl">
                <div className="my-8 text-gray-400">No Data Available yet</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export { RequestsData };
