import { createSlice } from "@reduxjs/toolkit";
import { bookSlots } from "../services";

const initialState = {
  slots: [],
  areSlotsBooked: false,
  error: null,
};

export const bookSlotSlice = createSlice({
  name: "book-slot",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(bookSlots.pending, (state) => {})
      .addCase(bookSlots.fulfilled, (state, action) => {
        state.areSlotsBooked = true;
        state.slots = action.payload.data;
      })
      .addCase(bookSlots.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default bookSlotSlice.reducer;
