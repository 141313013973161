import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  BookSlots,
  SlotRequests,
  ConfirmedSlot,
  Login,
  ReviewSlots,
  ViewSessions,
  EditSlots,
  ForgotPassword,
  ResetPassword,
  ConfirmedSessions,
  VideoCall,
  ServiceApprovals,
  Help,
} from "./pages";
import { Header, ProtectedRoutes, SideNav } from "./components";
import { HMSRoomProvider } from "@100mslive/react-sdk";
// import { navLinks } from "./utils/constants";

const App = () => {
  const { pathname } = useLocation();
  const [showNav, setShowNav] = useState(false);

  // hide side nav on login page and video call page
  const hideSideNav = Boolean(
    pathname === "/" || pathname.startsWith("/join-session")
  );

  // console.log(
  //   "path ",
  //   navLinks.find((nav) => nav.name === "Service Approvals").path
  // );

  return (
    <div className="min-h-[100vh] flex flex-col">
      <Header
        hideSideNav={hideSideNav}
        showNav={showNav}
        setShowNav={setShowNav}
      />
      <div
        className={
          (hideSideNav ? "" : "grid grow md:grid-cols-12 relative ") + "grow"
        }
      >
        {!hideSideNav && <SideNav showNav={showNav} setShowNav={setShowNav} />}
        <div
          className={
            hideSideNav
              ? "min-h-[calc(100vh-4rem)]"
              : "md:col-start-3 md:col-end-13 h-full"
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/join-session/:id"
              element={
                <HMSRoomProvider>
                  <VideoCall />
                </HMSRoomProvider>
              }
            />
            <Route element={<ProtectedRoutes />}>
              <Route path="/book-slots" element={<BookSlots />} />
              <Route path="/review-slots" element={<ReviewSlots />} />
              <Route path="/view-sessions" element={<ViewSessions />} />
              <Route path="/availability" element={<SlotRequests />} />
              <Route path="/selected-slots" element={<ConfirmedSlot />} />
              <Route path="/edit-slots" element={<EditSlots />} />
              <Route
                path="/confirmed-sessions"
                element={<ConfirmedSessions />}
              />
              <Route path="service-approval" element={<ServiceApprovals />} />
              <Route path="/help" element={<Help />} />
            </Route>
          </Routes>
          {showNav && (
            <div
              onClick={() => setShowNav(false)}
              className="absolute top-0 opacity-50 bg-neutral-700	 z-[2] w-full h-full"
            ></div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
