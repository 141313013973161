export const getBookedSlotDataByDay = (bookedSlots) => {
  const obj = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  };

  bookedSlots.forEach((slot) => {
    obj[slot.day] = [
      ...obj[slot.day],
      {
        id: slot.id,
        start_time: slot.start_time_id,
        end_time: slot.end_time_id,
      },
    ];
  });
  return obj;
};
