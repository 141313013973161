import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { RequestsData } from "../../components";
import { getSlotRequestData } from "../../redux/services/confirm-slots/getSlotRequestData";
import { getCoachConfirmedSlots } from "../../redux/services/confirm-slots/confirmSlotsPerRequest";
import LoadingSpinner from "../../assets/loading-spinner.gif";
import { getAdminRequests } from "../../redux/services/admin-requests/getAdminRequests";
import { getNoOfRequestData } from "../../utils/getNoOfRequestData";

const requestTypes = ["NEW", "ACTIVE", "PAST", "MISSED"];

function SlotRequests() {
  const [selectedRequestType, setSelectedRequestType] = useState(
    requestTypes[0]
  );

  const slotRequestData = useSelector((state) => state.confirmSlots);
  const authState = useSelector((state) => state.auth);
  const { requestsList } = useSelector((state) => state.adminRequest);
  const dispatch = useDispatch();

  const noOfRequestData = getNoOfRequestData(requestsList, authState.user.id);

  useEffect(() => {
    dispatch(
      getSlotRequestData({
        token: authState.token,
        coachId: authState.user.id,
        requestType: selectedRequestType,
      })
    );

    dispatch(getAdminRequests());

    dispatch(getCoachConfirmedSlots(authState.user.id));
  }, [dispatch, authState.token, authState.user.id, selectedRequestType]);

  return (
    <div className="flex h-full py-4">
      {/* <div className="flex flex-col h-full w-40 shrink-0 mr-2 ml-4">
        <span className="font-semibold mb-4 block text-center rounded bg-sky-100 p-2">
          Request Names
        </span>
        <ul className="h-full rounded">
          {requestIds.map((requestId) => (
            <li
              onClick={() => setSelectedRequestId(requestId)}
              title={requests[requestId]}
              className={`list-disc border-b mb-1 list-inside whitespace-nowrap	overflow-hidden text-ellipsis px-2 py-1 cursor-pointer hover:bg-sky-100  ${
                selectedRequestId === requestId
                  ? "border-sky-500 text-sky-600 font-semibold"
                  : ""
              }`}
            >
              {requests[requestId]}
            </li>
          ))}
        </ul>
      </div> */}

      <div className="grow h-full">
        <ul className="overflow-hidden flex mx-2 gap-2 border-b-2 border-tab justify-around">
          {requestTypes.map((requestType) => (
            <li
              onClick={() => {
                setSelectedRequestType(requestType);
              }}
              className={`cursor-pointer pt-2 pb-1 grow text-center  ${
                selectedRequestType === requestType
                  ? "bg-tab text-white hover:bg-tab font-semibold"
                  : "bg-tab-bg-inactive hover:bg-tab-bg-inactive-hover"
              } `}
            >
              {requestType} ({noOfRequestData[requestType]})
            </li>
          ))}
        </ul>

        <div className="">
          {slotRequestData.fetchingSlotRequestData === "success" ? (
            <RequestsData requestType={selectedRequestType} />
          ) : slotRequestData.fetchingSlotRequestData === "loading" ? (
            <div className="flex justify-center h-full overflow-hidden">
              <img
                className="w-[22rem] inline-block"
                src={LoadingSpinner}
                alt="Loading.."
              />
            </div>
          ) : slotRequestData.fetchingSlotRequestData === "failed" ? (
            <p>Error Loading Requests Data. Please try again</p>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export { SlotRequests };
