import { useReducer } from "react";
import { slotDataReducer } from "../utils/slotDataReducer";

export function useSlotReducer(initialState) {
  const [slotData, dispatchSlotData] = useReducer(
    slotDataReducer,
    initialState
  );
  return { dispatchSlotData, slotData };
}
