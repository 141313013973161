import React, { useEffect, useState } from "react";
import { Badge, Button, Modal, Tooltip } from "antd";
import {
  MdChatBubbleOutline,
  MdMic,
  MdMicOff,
  MdOutlineFullscreen,
  MdOutlineVideocam,
  MdOutlineVideocamOff,
} from "react-icons/md";

import { IoIosCall } from "react-icons/io";
import {
  useHMSStore,
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  useHMSActions,
  useHMSNotifications,
} from "@100mslive/react-sdk";

import { VirtualBackground } from "../virtual-background/VirtualBackground";
import { toast } from "react-toastify";

export const Controllers = ({ setIsChatActive, setRoomEnded }) => {
  const [newMessages, setNewMessages] = useState(0);
  const hmsActions = useHMSActions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const notification = useHMSNotifications();

  const toggleAudio = () => {
    if (audioEnabled) {
      hmsActions.setLocalAudioEnabled(false);
    } else {
      const constraints = {
        audio: true,
        video: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          hmsActions.setLocalAudioEnabled(true);
        })
        .catch(() => {
          // always check for errors at the end.
          toast.error("Please check your browser permissions or mic settings");
        });
    }
  };

  const toggleVideo = () => {
    if (videoEnabled) {
      hmsActions.setLocalVideoEnabled(false);
    } else {
      const constraints = {
        audio: true,
        video: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          hmsActions.setLocalVideoEnabled(true);
        })
        .catch(() => {
          // always check for errors at the end.
          toast.error(
            "Please check your browser permissions or camera settings"
          );
        });
    }
    hmsActions.setLocalVideoEnabled(!videoEnabled);
  };

  function toggleFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;
    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  useEffect(() => {
    if (!notification) {
      return;
    }
    switch (notification.type) {
      case "NEW_MESSAGE":
        if (
          notification.data.message === "TIME_EXTENDED" ||
          notification.data.message === "SESSION_GOING_TO_CLOSED"
        ) {
          break;
        } else {
          setNewMessages((prev) => prev + 1);
          break;
        }
      default:
        break;
    }
  }, [notification]);

  return (
    <div className="h-fit flex justify-center relative z-[1] bg-white gap-4 p-2 border-t-2 border-gray-300 ">
      <Tooltip title={audioEnabled ? "Mute" : "Unmute"}>
        <Button
          onClick={toggleAudio}
          className="inline-flex items-center justify-center"
          type="secondary"
          shape="circle"
          size="large"
          icon={audioEnabled ? <MdMic /> : <MdMicOff />}
        />
      </Tooltip>

      <Tooltip title={videoEnabled ? "Turn off camera" : "Turn on camera"}>
        <Button
          onClick={toggleVideo}
          className="inline-flex items-center justify-center"
          type="secondary"
          shape="circle"
          size="large"
          icon={videoEnabled ? <MdOutlineVideocam /> : <MdOutlineVideocamOff />}
        />
      </Tooltip>

      <Tooltip title={"Full screen"}>
        <Button
          onClick={() => {
            toggleFullScreen();
          }}
          className="inline-flex items-center justify-center"
          type="secondary"
          shape="circle"
          size="large"
          icon={<MdOutlineFullscreen />}
        />
      </Tooltip>

      <Badge count={newMessages} size="large">
        <Tooltip title="Chat">
          <Button
            onClick={() => {
              setNewMessages(0);
              setIsChatActive((prev) => !prev);
            }}
            className="inline-flex items-center justify-center"
            type="secondary"
            shape="circle"
            size="large"
            icon={<MdChatBubbleOutline />}
          />
        </Tooltip>
      </Badge>
      <Button
        danger
        className="inline-flex items-center bg-[#fa352a] text-[#fff] justify-center"
        type="secondary"
        shape="circle"
        size="large"
        title="Leave Room"
        onClick={() => {
          // hmsActions.leave();
          // setRoomEnded(true);
          setIsModalOpen(true);
        }}
      >
        <IoIosCall />
      </Button>
      {isModalOpen && (
        <Modal
          className="rounded-lg"
          footer={false}
          visible={true}
          open={true}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <div className="">
            <p className="mb-4">Are you sure want to end the session?</p>
            <div className="flex gap-2 ">
              <Button
                onClick={() => {
                  hmsActions.leave();
                  setRoomEnded(true);
                }}
                className="text-red-500"
                type="danger"
              >
                Yes
              </Button>
              <Button
                type="secondary"
                onClick={() => setIsModalOpen(false)}
                className=""
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <VirtualBackground />
    </div>
  );
};
