import React, { useState } from "react";

const hours = (() => {
  const res = [];
  for (let hour = 1; hour <= 12; hour++) {
    res.push(hour < 10 ? `0${hour}` : `${hour}`);
  }
  return res;
})();

const minutes = (() => {
  const res = [];
  for (let minute = 0; minute < 60; minute += 15) {
    res.push(minute < 10 ? `0${minute}` : `${minute}`);
  }
  return res;
})();

const DropdownWrapper = ({ setTime, setDropdownActive }) => {
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [selectedMeridian, setSelectedMeridian] = useState("");

  const selectHourHandler = (hour) => {
    if (hour === "00") {
      setSelectedMeridian("AM");
    }
    if (+hour > 9 && selectedMeridian === "PM") {
      setSelectedMeridian("");
    }
    if (+hour < 7 && selectedMeridian === "AM") {
      setSelectedMeridian("");
    }
    setSelectedHour(hour);
  };

  return (
    <div
      className={
        "dropdown absolute top-14  bg-white gap-2 p-t-2 overflow-hidden shadow-indigo-500/40 shadow-lg z-[1]"
      }
    >
      <div className="flex max-h-[200px]">
        <ul className="overflow-y-scroll border-r-2 px-2 min-w-fit flex flex-col scrollbar-thin scrollbar-thumb-sky-200">
          <span className="text-center">Hours</span>
          {hours.map((hour) => (
            <li
              className={
                (selectedHour === hour ? "bg-sky-500 " : " ") +
                "text-center cursor-pointer px-4"
              }
              onClick={() => selectHourHandler(hour)}
            >
              {hour}
            </li>
          ))}
        </ul>
        <ul className=" min-w-fit flex flex-col border-r-2 px-2">
          <span>Minutes</span>
          {minutes.map((minute) => (
            <li
              className={
                (selectedMinute === minute ? "bg-sky-500 " : " ") +
                "text-center cursor-pointer px-4"
              }
              onClick={() => setSelectedMinute(minute)}
            >
              {minute}
            </li>
          ))}
        </ul>
        <ul className="flex flex-col px-2">
          <li
            onClick={() => {
              if (selectedHour && selectedHour >= 7) {
                setSelectedMeridian("AM");
              }
            }}
            className={
              (selectedMeridian === "AM" ? "bg-sky-500 " : "") +
              "text-center cursor-pointer px-4 " +
              (!selectedHour || +selectedHour < 7 ? "text-slate-300 " : "")
            }
          >
            AM
          </li>
          <li
            onClick={() => {
              if (selectedHour && selectedHour <= 9) {
                setSelectedMeridian("PM");
              }
            }}
            className={
              (selectedMeridian === "PM" ? "bg-sky-500 " : "") +
              "text-center cursor-pointer px-4 " +
              (!selectedHour || selectedHour > 9 ? "text-slate-300 " : "")
            }
          >
            PM
          </li>
        </ul>
      </div>
      <button
        className="w-full text-center p-2 bg-sky-300 disabled:cursor-not-allowed"
        disabled={!(selectedHour && selectedMinute && selectedMeridian)}
        onClick={() => {
          setTime(`${selectedHour}:${selectedMinute} ${selectedMeridian}`);
          setDropdownActive(false);
        }}
      >
        Add time
      </button>
    </div>
  );
};

export { DropdownWrapper };
