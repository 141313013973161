import React, { useState } from "react";
import { DayWrapper } from "../../components";
import { emptySlotData } from "../../utils/constants";
import { useSlotReducer } from "../../custom-hooks/useSlotReducer";
import { daysArray } from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { getSlots, reviewSlots } from "../../redux/services";
import { useEffect } from "react";
import { Button } from "antd";
import { getCurrentWeekId } from "../../utils/getCurrentWeekId";
import { getBookedSlotDataByDay } from "../../utils/getBookedSlotDataByDay";

const ReviewSlots = () => {
  const { slotData, dispatchSlotData } = useSlotReducer(emptySlotData);
  const [isAddSlotActive, setIsAddSlotActive] = useState(false);

  const reviewSlotState = useSelector((state) => state.reviewSlot);

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const confirmSlotsHandler = async () => {
    const slots = (() => {
      let arr = [];
      for (let day in slotData) {
        slotData[day].forEach((slot) => {
          if (slot.start_time && slot.end_time) {
            arr.push({
              coach: `${authState.user.id}`,
              day: day,
              start_time_id: `${
                typeof slot.start_time == "string"
                  ? slot.start_time
                  : slot.start_time._d.getTime()
              }`,
              end_time_id: `${
                typeof slot.end_time == "string"
                  ? slot.end_time
                  : slot.end_time._d.getTime()
              }`,
              week_id: `${getCurrentWeekId()}`,
            });
          }
        });
      }
      return arr;
    })();

    if (slots.length === 0) {
      alert("Select slots to confirm and save");
    } else {
      dispatch(reviewSlots({ token: authState.token, slots: slots }));
    }
  };

  // getting slots on initial render
  useEffect(() => {
    dispatch(getSlots({ token: authState.token }));
  }, [authState.token, dispatch]);

  useEffect(() => {
    if (reviewSlotState.areSlotsConfirmed) {
      setIsAddSlotActive(false);
    }
  }, [reviewSlotState.areSlotsConfirmed]);

  // updating slot data once we get slots
  useEffect(() => {
    if (reviewSlotState.slots.length > 0) {
      dispatchSlotData({
        type: "UPDATE_SLOT_DATA",
        payload: getBookedSlotDataByDay(
          reviewSlotState.slots.filter(
            (slot) => {
              if(slot.for_learners === false && slot.coach === Number(authState.user.id)){
                return true;
              }
              return false;
            } 
          )
        ),
      });
    }
  }, [reviewSlotState.slots, dispatchSlotData, authState]);

  if (isAddSlotActive) {
    return (
      <main className="py-4 px-4 md:pl-16 ">
        <h2 className="text-center mb-2 bg-gray-100 font-semibold rounded-md py-2">
          Hey dummy user, please confirm your available slots
        </h2>
        <div className="flex m-auto flex-col rounded-lg w-fit mb-2">
          {daysArray.map((day) => (
            <DayWrapper
              key={day}
              day={day}
              slotData={slotData}
              dispatchSlotData={dispatchSlotData}
            />
          ))}
        </div>
        <Button
          type="primary"
          size="default"
          className="block w-fit font-semibold px-2 p-1 rounded mt-4 m-auto"
          onClick={confirmSlotsHandler}
        >
          Confirm and Save
        </Button>
      </main>
    );
  } else {
    return (
      <main className="my-4 mx-8">
        <h2 className="text-center mb-2 bg-gray-100 font-semibold rounded-md py-2">
          {reviewSlotState.areSlotsConfirmed
            ? "Your confirmed slots are"
            : "Please review your available slots or change them for the upcoming week ... to ..."}
        </h2>{" "}
        <section className="bg-sky-100 mt-8 p-4">
          {(() => {
            const data = getBookedSlotDataByDay(reviewSlotState.slots);
            return daysArray.map((day) => {
              return (
                <ul>
                  <h4 className="mb-[2px]">{day}</h4>
                  <ul className="flex gap-x-4 gap-y-2 flex-wrap">
                    {data[day].map((slot) => (
                      <li className="border-2 border-sky-600 px-4 min-w-fit relative">
                        {new Date(+slot.start_time).toLocaleTimeString()} -
                        {new Date(+slot.end_time).toLocaleTimeString()}
                      </li>
                    ))}
                  </ul>
                </ul>
              );
            });
          })()}
        </section>
        {!reviewSlotState.areSlotsConfirmed && (
          <section className="flex flex-col justify-center gap-4 pt-4 ">
            <button
              className="bg-sky-500 px-4 rounded self-start p-1 "
              onClick={() => setIsAddSlotActive(true)}
            >
              Edit slots
            </button>
            <button
              className="bg-lime-400 px-4 rounded self-start p-1"
              onClick={() => {
                confirmSlotsHandler();
              }}
            >
              Confirm and Save
            </button>
          </section>
        )}
      </main>
    );
  }
};

export { ReviewSlots };
