import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBookedSessions = createAsyncThunk(
  "get-booked-sessions",
  async (coachId, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}booked-slot-by-coach/${coachId}/` // returns learner's confirmed slot of a coach
      );
      return res.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
