import { useRef } from "react";
import { useHMSActions } from "@100mslive/react-sdk";
import { useLocation, useParams } from "react-router-dom";
import { Button, Input } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import MeeraqBG from "../../assets/meeraq_bg.jpg";
import MeeraqInfinityIcon from "../../assets/meeraq_infinity_icon.jpg";

function MeetingJoinForm({ setCoachingSessionDetails }) {
  const hmsActions = useHMSActions();
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const [error, setError] = useState(null);
  const [isJoinLoading, setIsJoinLoading] = useState(false);

  const { isLoggedIn, user: coach } = useSelector((state) => state.auth);
  const location = useLocation();
  const { id: room_id } = useParams();

  const getToken = async (user_id) => {
    const response = await fetch(process.env.REACT_APP_GENERATE_TOKEN_API, {
      method: "POST",
      body: JSON.stringify({
        user_id,
        role: "host",
        type: "app",
        room_id: room_id,
      }),
    });

    const { token } = await response.json();
    return token;
  };

  const handleSubmit = async (e) => {
    setIsJoinLoading(true);
    e.preventDefault();
    try {
      const responseFromGetCurrentSession = await axios.post(
        `${process.env.REACT_APP_BASE_URL}current-booked-slot/`,
        {
          learner_email: emailRef.current.input.value,
          room_id: room_id,
          time: new Date().getTime(),
        }
      );
      setCoachingSessionDetails(responseFromGetCurrentSession.data.data);
      try {
        const token = await getToken(nameRef.current.input.value);
        hmsActions.join({
          userName: nameRef.current.input.value,
          authToken: token,
          metaData: {
            email: emailRef.current.input.value,
          },
        });
        // setIsJoinLoading(false)
      } catch (err) {
        // if fails to join call
        console.log(err);
        toast.error("Unable to join the session");
        setIsJoinLoading(false);
      }
    } catch (err) {
      // if no slot found
      if (err.response.status === 401) {
        toast.error(
          "Session not found. Please join session on scheduled date and time."
        );
      } else {
        toast.error("Invalid Link");
      }
      setIsJoinLoading(false);
    }
  };

  const joinSession = async () => {
    if (location.state) {
      if (location.state.endTime) {
      }

      try {
        // const response = await axios.post(
        //   `${process.env.REACT_APP_BASE_URL}confirm-coach-joined/${location.state.id}`
        // );
        // console.log(response);
        const token = await getToken(coach.firstName);
        hmsActions.join({
          userName: coach.firstName,
          authToken: token,
          metaData: {
            email: coach.username,
          },
        });
      } catch (err) {
        setError(
          "Error joining session. We hope you have joined session from Meeraq website"
        );
      }

      console.log("url state", location.state);

      const token = await getToken(coach.firstName);
      hmsActions.join({
        userName: coach.firstName,
        authToken: token,
        metaData: {
          email: coach.username,
        },
      });
    } else {
      setError(
        "Error joining session. We hope you have joined session from Meeraq website"
      );
    }
  };

  if (isLoggedIn) {
    if (error) {
      return <p className="p-2">{error}</p>;
    } else {
      joinSession();
    }
  } else {
    return (
      <div className="relative h-[91vh] flex flex-col gap-4 items-center justify-center">
        <img
          className="absolute opacity-75 z-[1] block w-full h-full top-0"
          src={MeeraqBG}
          alt=""
        />
        <div className="px-12 pt-8 pb-12 border rounded-xl shadow-2xl mb-4 z-[2] flex flex-col gap-4 bg-white w-fit relative items-center">
          <header className="font-semibold ">
            <img
              src={MeeraqInfinityIcon}
              className="w-[50px] h-[50px] block mx-auto"
              alt="meeraq logo"
            />
            <h1 className="text-lg">Coaching Session</h1>
          </header>
          <form
            className="flex flex-col gap-3 min-w-[21rem]"
            onSubmit={handleSubmit}
          >
            <div>
              <label htmlFor="name" className="block mb-1">
                Name: <span className="text-red-500">*</span>
              </label>
              <Input
                ref={nameRef}
                placeholder="Enter name"
                required
                type="text"
                name="name"
                id="name"
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-1">
                Email: <span className="text-red-500">*</span>
              </label>
              <Input
                ref={emailRef}
                placeholder="Enter email"
                required
                type="email"
                name="email"
                id="text"
              />
            </div>
            <Button
              loading={isJoinLoading}
              htmlType="submit"
              type="primary"
              className="w-fit rounded mx-auto"
            >
              Join Session
            </Button>
          </form>
        </div>

        <div className="font-semibold text-white z-[4]">
          © 2022 Meeraq. All Rights Reserved.
        </div>
      </div>
    );
  }
}

export { MeetingJoinForm };
