import React from "react";
import { DayWrapper } from "../../components";
import { useSlotReducer } from "../../custom-hooks/useSlotReducer";
import { emptySlotData, daysArray } from "../../utils/constants";
import { Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bookSlots } from "../../redux/services";
import { getCurrentWeekId } from "../../utils/getCurrentWeekId";
import { getBookedSlotDataByDay } from "../../utils/getBookedSlotDataByDay";

export const BookSlots = () => {
  const { slotData, dispatchSlotData } = useSlotReducer(emptySlotData);
  const authState = useSelector((state) => state.auth);
  const { slots: confirmedSlots, areSlotsBooked } = useSelector(
    (state) => state.bookSlot
  );

  const dispatch = useDispatch();

  const confirmSlotsHandler = async () => {
    const slots = (() => {
      let arr = [];
      for (let day in slotData) {
        slotData[day].forEach((slot) => {
          if (slot.start_time && slot.end_time) {
            arr.push({
              coach: `${authState.user.id}`, // have to update based on coach id - currently getting user id on login
              day: day,
              start_time_id: `${slot.start_time._d.getTime()}`,
              end_time_id: `${slot.end_time._d.getTime()}`,
              week_id: `${getCurrentWeekId()}`,
            });
          }
        });
      }
      return arr;
    })();

    if (slots.length === 0) {
      alert("Select slots to confirm and save");
    } else {
      dispatch(bookSlots({ token: authState.token, slots: slots }));
    }
  };

  if (areSlotsBooked) {
    return (
      <main className="my-4 mx-8">
        <section className="bg-sky-100 mt-8 p-4">
          <h3 className="font-bold text-lg">Your confirmed slots are:</h3>

          {(() => {
            const data = getBookedSlotDataByDay(confirmedSlots);
            return daysArray.map((day) => {
              return (
                <ul>
                  <h4 className="mb-[2px]">{day}</h4>
                  <ul className="flex gap-x-4 gap-y-2 flex-wrap">
                    {data[day].map((slot) => (
                      <li className="border-2 border-sky-600 px-4 min-w-fit relative">
                        {new Date(+slot.start_time).toLocaleTimeString()} -
                        {new Date(+slot.end_time).toLocaleTimeString()}
                      </li>
                    ))}
                  </ul>
                </ul>
              );
            });
          })()}
        </section>
        <section className="bg-sky-100 mt-8 p-4">
          <div>
            <p className="mb-2"> Thank you!</p>
            <p className="mb-2">
              You will be able to review your slots on a weekly basis as and
              when a batch is ready for coaching.
            </p>
            <p className="mb-2">You may log out for now.</p>{" "}
          </div>
          <button className="block text-lg min-w-full bg-sky-500 mt-4 py-[2px]">
            Log out
          </button>
        </section>
      </main>
    );
  } else {
    return (
      <main className="py-4 px-4 md:pl-16 ">
        <h2 className="text-center mb-2 bg-gray-100 font-semibold rounded-md py-2">
          Hey dummy user, please select your available slots
        </h2>
        <div className="flex m-auto flex-col rounded-lg w-fit mb-2">
          {daysArray.map((day) => (
            <DayWrapper
              key={day}
              day={day}
              slotData={slotData}
              dispatchSlotData={dispatchSlotData}
            />
          ))}
        </div>
        <Button
          type="primary"
          size="default"
          className="block w-fit font-semibold px-2 p-1 rounded mt-4 m-auto"
          onClick={confirmSlotsHandler}
        >
          Confirm and Save
        </Button>
      </main>
    );
  }
};
