import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const deleteConfirmSlot = createAsyncThunk(
  "delete-confirm-slots",
  async (
    { getCoachConfirmedSlots, coachId, slotId },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DELETE_COACH_CONFIRMED_SLOTS}${coachId}/${slotId}`
      );
      if (res.status === 200) {
        dispatch(getCoachConfirmedSlots(coachId));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export { deleteConfirmSlot };
