import React, { useRef } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { Link, Navigate } from "react-router-dom";
import { login } from "../../redux/services";
import LoadingSpinner from "../../assets/loading-spinner.gif";

const Login = () => {
  const { isLoggedIn, loginError, fetchingUser } = useSelector(
    (state) => state.auth
  );
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (passwordRef && loginError) {
      passwordRef.current.value = "";
    }
  }, [loginError]);

  const loginHandler = () => {
    dispatch(
      login({
        username: emailRef.current.value.toLowerCase().trim(),
        password: passwordRef.current.value,
      })
    );
  };

  if (isLoggedIn) {
    return <Navigate to={"/availability"} replace={true} />;
  } else {
    if (fetchingUser === "loading") {
      return (
        <section className="flex items-center justify-center mt-6">
          <img
            className="w-[22rem]"
            src={LoadingSpinner}
            alt="loading spinner"
          />
        </section>
      );
    } else {
      return (
        <section className="px-2">
          <h2 className="font-medium mt-8 text-2xl text-center">LOGIN</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              loginHandler();
            }}
            className="w-full sm:w-96 flex flex-col gap-2 mx-auto"
          >
            <label htmlFor="email" className="block">
              Email: <span className="text-red-500">*</span>
            </label>
            <input
              placeholder="Input email"
              required
              type="email"
              name="email"
              id="email"
              ref={emailRef}
              className="p-1 mb-2 w-full rounded border outline-none"
            />
            <label htmlFor="password" className="block">
              Password: <span className="text-red-500">*</span>
            </label>
            <input
              placeholder="Input password"
              required
              type="password"
              name="password"
              id="password"
              className="p-1 mb-2 w-full rounded border outline-none"
              ref={passwordRef}
            />
            <Link
              to="/forgot-password"
              className="text-right text-sm mt-2 cursor-pointer hover:underline"
            >
              Forgot Password?
            </Link>
            <div className="text-center mt-3">
              <button className="px-4 py-1 rounded hover:bg-primary-ctc-hover bg-primary-ctc text-white">
                Login
              </button>
            </div>
          </form>
        </section>
      );
    }
  }
};

export { Login };
