export const getSlotsPerRequestByDate = (slots, requestId) => {
  let slotsByDate = {};
  slots.forEach((slot) => {
    if (slot.request === +requestId || slot.request_ID === requestId) {
      if (slotsByDate[slot.date]) {
        slotsByDate[slot.date] = [
          ...slotsByDate[slot.date],
          {
            id: slot.id,
            start_time: slot.start_time,
            end_time: slot.end_time,
          },
        ];
      } else {
        slotsByDate[slot.date] = [
          {
            id: slot.id,
            start_time: slot.start_time,
            end_time: slot.end_time,
          },
        ];
      }
    }
  });
  return slotsByDate;
};
