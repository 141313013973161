import { createSlice } from "@reduxjs/toolkit";
import { getAdminRequests } from "../services";

const initialState = {
  requestsList: [],
  fetchingAdminRequest: "idle",
};

const adminRequestSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAdminRequests.pending, (state) => {
        state.fetchingAdminRequest = "loading";
      })
      .addCase(getAdminRequests.fulfilled, (state, action) => {
        state.fetchingAdminRequest = "success";
        state.requestsList = action.payload.Data;
      })
      .addCase(getAdminRequests.rejected, (state) => {
        state.fetchingAdminRequest = "failed";
      });
  },
});

export default adminRequestSlice.reducer;
