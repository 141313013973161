export const sortTime = (dateList, data) => {
  const givenData = { ...data };
  dateList.forEach((date) => {
    givenData[date] = [...givenData[date]];
    givenData[date].sort((a, b) => {
      const nameA = new Date(+a.start_time).toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      const nameB = new Date(+b.start_time).toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  });
  return givenData;
};
