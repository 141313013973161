import uuid from "react-uuid";

export function slotDataReducer(state, action) {
  switch (action.type) {
    case "SET_TIME":
      const { day: currDay, time, id, type } = action.payload;

      return {
        ...state,
        [currDay]: state[currDay].map((slot) => {
          if (slot.id === id) {
            if (type === "START_TIME") {
              return {
                ...slot,
                start_time: time,
              };
            } else if (type === "END_TIME") {
              return {
                ...slot,
                end_time: time,
              };
            }
          }
          return { ...slot };
        }),
      };
    case "ADD_SLOT":
      return {
        ...state,
        [action.payload]: [
          ...state[action.payload],
          { id: uuid(), start_time: "", end_time: "" },
        ],
      };
    case "DELETE_SLOT":
      return {
        ...state,
        [action.payload.day]: state[action.payload.day].filter(
          (slot) => slot.id !== action.payload.id
        ),
      };
    case "UPDATE_SLOT_DATA":
      return { ...action.payload };
    default:
      throw new Error();
  }
}
