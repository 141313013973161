import { useHMSActions } from "@100mslive/react-sdk";
import { Button, Tooltip } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { MdInfoOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTime, useTimer } from "react-timer-hook";

const FIVE_MINUTES = 300;

function Timer({ coachingSessionDetails, setRoomEnded }) {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const location = useLocation();
  const [coachingSessionStartTime, setCoachingSessionStartTime] =
    useState(false);
  const [isSessionExtended, setIsSessionExtended] = useState(true);
  const isUserNotified = useRef(false); // user notification for closing of session
  const [coachingSessionEndTime, setCoachingSessionEndTime] = useState(
    isLoggedIn
      ? new Date(
          `${location.state.date.split("-").reverse().join("-")} ${
            location.state.endTime
          }`
        )
      : new Date(+coachingSessionDetails.slot.end_time)
  );

  const hmsActions = useHMSActions();

  const endRoom = async () => {
    try {
      const lock = false; // set to true to disallow rejoins
      const reason = "Session duration complete";
      await hmsActions.endRoom(lock, reason);
      setRoomEnded(true);
    } catch (error) {
      // Permission denied or not connected to room
      console.error(error);
    }
  };
  const { seconds: currentSeconds } = useTime({ format: "12-hour" });
  console.log(currentSeconds);
  const currentTime = new Date();

  const { seconds, minutes, hours, isRunning, restart } = useTimer({
    expiryTimestamp: coachingSessionEndTime,
    autoStart: false,
    onExpire: () => {
      endRoom();
    },
  });

  if (minutes < 2 && !isUserNotified.current) {
    hmsActions.sendBroadcastMessage("SESSION_GOING_TO_CLOSED");
    isUserNotified.current = true;
  }

  if (coachingSessionStartTime) {
    if (!isRunning) {
      if (
        currentTime.getTime() >= coachingSessionStartTime.getTime() &&
        currentTime.getTime() < coachingSessionEndTime.getTime()
      ) {
        restart(coachingSessionEndTime);
      }
    }
  }

  const extendTimeByFiveMinutes = () => {
    hmsActions.sendBroadcastMessage("TIME_EXTENDED");
    isUserNotified.current = false;
    setCoachingSessionEndTime((prev) => {
      const time = new Date(prev);
      time.setSeconds(time.getSeconds() + FIVE_MINUTES);
      return time;
    });
  };

  useEffect(() => {
    if (isRunning) {
      restart(coachingSessionEndTime);
    }
  }, [coachingSessionEndTime, isRunning, restart]);

  useEffect(() => {
    if (!isLoggedIn) {
      const startTime = new Date(+coachingSessionDetails.slot.start_time);
      setCoachingSessionStartTime(startTime);
    } else {
      const formattedDate = location.state.date.split("-").reverse().join("-");
      const startTime = new Date(`${formattedDate} ${location.state.time}`);
      setCoachingSessionStartTime(startTime);
    }
  }, [isLoggedIn, coachingSessionDetails, location]);

  return (
    <div className="absolute left-[50%] top-0 -translate-x-1/2	z-[1] ">
      {isRunning && (
        <div className="flex flex-col items-center font-semibold">
          <p>
            {minutes >= 5 ? (
              <p className="bg-success/10 border border-success py-1 px-2 mt-2 ">
                Time Remaining: {hours > 0 ? `${hours}:` : ""}
                {String(minutes).padStart(2, "0")}:
                {String(seconds).padStart(2, "0")} Minutes
              </p>
            ) : (
              <div className="">
                <p className="bg-red-600/10 border border-red-600	py-1 px-2 mt-2 w-fit ">
                  Time Remaining: {hours > 0 ? `${hours}:` : ""}
                  {String(minutes).padStart(2, "0")}:
                  {String(seconds).padStart(2, "0")} Minutes
                </p>
              </div>
            )}
          </p>
          {minutes < 5 && isSessionExtended && (
            <div className="flex gap-2 p-2 border-2 rounded backdrop-blur-md	">
              <Button type="primary" onClick={() => extendTimeByFiveMinutes()}>
                Extend Five Minutes
              </Button>
              <Tooltip
                placement="bottom"
                title="Session will be closed automatically once the timer ends"
              >
                <Button
                  className="flex flex-row-reverse items-center gap-1"
                  type="secondary"
                  icon={<MdInfoOutline />}
                  onClick={() => setIsSessionExtended(false)}
                >
                  Cancel
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export { Timer };
