import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getSlotsByDate } from "../../utils/getSlotsByDate";
import { ConfirmSlotModal } from "./confirm-slot-modal/ConfirmSlotModal";
import {
  convertTimeStampTo12HourString,
  convertTimeStampTo24HourString,
  getSlotsPerRequestByDate,
  sortTime,
} from "../../utils";
import { Button } from "antd";
import { format } from "date-fns";
import { MdDelete } from "react-icons/md";

const RequestTypeNew = ({
  selectedRequestId,
  requestType,
  confirmedSlotsPerRequestByDate,
}) => {
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [isAddMoreSlotsActive] = useState(false);
  const [confirmSlotModalDetails, setConfirmSlotModalDetails] = useState(null);
  const {
    requests,
    updatingSlotStatus,
    slotsUpdatedByCurrentRequest,
    availableSlots,
    confirmedSlots,
  } = useSelector((state) => state.confirmSlots);
  const authState = useSelector((state) => state.auth);
  const [selectedSlotIds, setSelectedSlotIds] = useState([]);

  const datesPerRequest = Object.keys(confirmedSlotsPerRequestByDate);

  const formattedData = getSlotsByDate(
    slotsUpdatedByCurrentRequest[selectedRequestId]
      ? slotsUpdatedByCurrentRequest[selectedRequestId]
      : []
  );

  // only showing upcoming slots to provide
  const upcomingAvaiailableSlots = availableSlots.filter(
    (slot) => slot.start_time > +new Date().getTime()
  );
  let data = getSlotsPerRequestByDate(
    upcomingAvaiailableSlots,
    selectedRequestId
  );
  const allDates = Object.keys(data);
  // sorting dates
  const sortedDates = allDates.sort();
  // sorting slots based on start time
  data = sortTime(sortedDates, data);

  const slotClickHandler = (date, slot) => {
    if (selectedSlotIds.includes(slot.id)) {
      setSelectedSlotIds((prev) => prev.filter((id) => id !== slot.id));
      setSelectedSlots((prev) =>
        prev.filter((slotItem) => slotItem.id !== slot.id)
      );
    } else {
      setSelectedSlotIds((prev) => [...prev, slot.id]);
      setSelectedSlots((prev) => [
        ...prev,
        {
          id: slot.id,
          start_time: slot.start_time,
          end_time: slot.end_time,
          date: date,
        },
      ]);
    }
  };

  return (
    <section className="grow">
      {selectedRequestId &&
        updatingSlotStatus?.map((slotStatus) => {
          if (slotStatus.requestId === Number(selectedRequestId)) {
            // when selected request id request status is idle
            if (slotStatus.status === "idle") {
              return (
                <>
                  <section className="flex w-[95%] mx-auto flex-col gap-2">
                    {requestType === "NEW" ? (
                      <p className="mt-2">
                        Hey {authState.user.firstName}, please select your
                        availability on these dates for{" "}
                        <span className="font-semibold">
                          {requests[selectedRequestId]}{" "}
                        </span>
                        :
                      </p>
                    ) : (
                      <div className="flex justify-between">
                        <div
                          className={
                            datesPerRequest.length > 0
                              ? `text-lg mt-2 w-[95%] mx-auto`
                              : "mt-2"
                          }
                        >
                          {isAddMoreSlotsActive ? (
                            <div>
                              Edit your slots{" "}
                              <div className="flex gap-2">
                                Hint:
                                <span className="bg-sky-200 px-2">
                                  Already Confirmed Slots
                                </span>
                                <span className="bg-sky-500 px-2">
                                  Slots to be added
                                </span>
                                <span className="px-2 border">
                                  Available Slots
                                </span>
                              </div>
                            </div>
                          ) : datesPerRequest.length > 0 ? (
                            "The selected slots are:"
                          ) : (
                            <div className="my-6">
                              No slots available. You either did not pick any
                              slot or you deleted all your slots from this
                              request.
                            </div>
                          )}{" "}
                        </div>
                      </div>
                    )}

                    {!(requestType === "NEW" && isAddMoreSlotsActive) &&
                      Object.keys(data).map((date) => {
                        let myDate = date.split("-");
                        var formattedDates = new Date(
                          `${myDate[0]}-${myDate[1]}-${myDate[2]}`
                        );
                        return (
                          <section key={date} className="py-1">
                            <h3 className="font-bold text-slate-800 px-4 py-2 rounded bg-slate-300">
                              {format(formattedDates, "dd-MM-yyyy (EEEE)")} :
                            </h3>
                            <div className="flex gap-4 mt-2 py-2 pl-6  flex-wrap">
                              {data[date].map((slot) => {
                                for (
                                  let i = 0;
                                  i < confirmedSlots.length;
                                  i++
                                ) {
                                  if (
                                    confirmedSlots[i].date === date &&
                                    new Date(
                                      Number(confirmedSlots[i].start_time) -
                                        30 * 60000
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: false,
                                    }) <=
                                      convertTimeStampTo24HourString(
                                        slot,
                                        "start_time"
                                      ) &&
                                    new Date(
                                      Number(confirmedSlots[i].end_time) +
                                        30 * 60000
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: false,
                                    }) >
                                      convertTimeStampTo24HourString(
                                        slot,
                                        "end_time"
                                      )
                                  ) {
                                    if (requestType === "NEW") {
                                      return (
                                        <span
                                          key={slot.id}
                                          className={`border p-2 inline-block cursor-not-allowed bg-zinc-300`}
                                        >
                                          {convertTimeStampTo12HourString(
                                            slot,
                                            "start_time"
                                          )}{" "}
                                          -{" "}
                                          {convertTimeStampTo12HourString(
                                            slot,
                                            "end_time"
                                          )}
                                        </span>
                                      );
                                    } else if (requestType === "ACTIVE") {
                                      return (
                                        <div className="relative">
                                          <span
                                            key={slot.id}
                                            className={`border p-2 inline-block bg-sky-200`}
                                          >
                                            {convertTimeStampTo12HourString(
                                              slot,
                                              "start_time"
                                            )}{" "}
                                            -{" "}
                                            {convertTimeStampTo12HourString(
                                              slot,
                                              "end_time"
                                            )}
                                          </span>
                                          <span className="bg-white hover:text-red-600 cursor-pointer text-lg absolute rounded right-[-6px] top-[-4px]">
                                            <MdDelete />
                                          </span>
                                        </div>
                                      );
                                    }
                                  }
                                }
                                return (
                                  <span
                                    onClick={() => slotClickHandler(date, slot)}
                                    key={slot.id}
                                    className={`border p-2 inline-block cursor-pointer ${
                                      selectedSlotIds.includes(slot.id)
                                        ? "bg-sky-500 border-sky-500 text-white"
                                        : "rounded bg-white"
                                    }`}
                                  >
                                    {convertTimeStampTo12HourString(
                                      slot,
                                      "start_time"
                                    )}{" "}
                                    -{" "}
                                    {convertTimeStampTo12HourString(
                                      slot,
                                      "end_time"
                                    )}
                                  </span>
                                );
                              })}
                            </div>
                          </section>
                        );
                      })}

                    {requestType === "NEW" && (
                      <Button
                        type="primary"
                        className="my-6 mx-auto rounded w-fit"
                        onClick={() =>
                          setConfirmSlotModalDetails({
                            selectedSlots,
                            selectedRequestId,
                          })
                        }
                      >
                        Confirm Slots
                      </Button>
                    )}
                  </section>
                  {confirmSlotModalDetails && (
                    <ConfirmSlotModal
                      confirmSlotModalDetails={confirmSlotModalDetails}
                      setConfirmSlotModalDetails={setConfirmSlotModalDetails}
                    />
                  )}
                </>
              );
            }

            if (slotStatus.status === "loading") {
              return <div> Loading....</div>;
            }

            if (slotStatus.status === "success") {
              return (
                <div className="w-[95%] mx-auto">
                  {slotsUpdatedByCurrentRequest[selectedRequestId]?.length >
                    0 && <p className="py-2">Slots updated : </p>}

                  {slotsUpdatedByCurrentRequest[selectedRequestId]?.length >
                  0 ? (
                    Object.keys(formattedData).map((date) => {
                      let data = formattedData[date];
                      let children = data.map((slot) => (
                        <span className="border p-2 inline-block bg-sky-300">
                          {convertTimeStampTo12HourString(slot, "start_time")} -{" "}
                          {convertTimeStampTo12HourString(slot, "end_time")}
                        </span>
                      ));
                      return (
                        <>
                          <h3 className="font-bold text-slate-800 px-4 py-2 rounded bg-slate-300">
                            {format(new Date(date), "dd-MM-yyyy (EEEE)")} :
                          </h3>
                          <div className="pl-6 py-2 flex gap-2 flex-wrap">
                            {children}
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <p className="mt-2">0 slots updated for this request.</p>
                  )}

                  <p className="text-center text-lg my-4">
                    Thank you for your confirmation.
                  </p>
                </div>
              );
            }
            if (slotStatus.status === "failed") {
              return (
                <p className="text-center my-6">
                  Failed to update.. Please try again.
                </p>
              );
            }
          }
          return null;
        })}
    </section>
  );
};

export { RequestTypeNew };
