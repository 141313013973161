import { format } from "date-fns";
import React, { useState } from "react";
import { convertTimeStampTo12HourString } from "../../utils";

const RequestTypeActive = ({ requestType, confirmedSlotsPerRequestByDate }) => {
  const [isAddMoreSlotsActive] = useState(false);
  const datesPerRequest = Object.keys(confirmedSlotsPerRequestByDate);

  if (datesPerRequest.length === 0) {
    return (
      <section className="my-6 text-center">
        No slots available. You either did not pick any slot or you deleted all
        your slots from this request.
      </section>
    );
  }

  return (
    Boolean(requestType === "ACTIVE" && !isAddMoreSlotsActive) &&
    datesPerRequest.map((date) => {
      let myDate = date.split("-");
      var formattedDates = new Date(`${myDate[0]}-${myDate[1]}-${myDate[2]}`);
      return (
        <section className="w-[97%] mt-2 mx-auto">
          <h3 className="font-bold text-slate-800 px-4 py-2 rounded bg-slate-300">
            {format(formattedDates, "dd-MM-yyyy (EEEE)")} :
          </h3>
          <div className="flex mt-3 mb-6 mx-auto w-[95%] gap-2 flex-wrap">
            {confirmedSlotsPerRequestByDate[date].map((slot) => (
              <span
                key={slot.id}
                className={`border p-2 inline-block bg-sky-300`}
              >
                {convertTimeStampTo12HourString(slot, "start_time")} -{" "}
                {convertTimeStampTo12HourString(slot, "end_time")}
              </span>
            ))}
          </div>
        </section>
      );
    })
  );
};

export { RequestTypeActive };
