import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const forgotPassword = createAsyncThunk(
  "forgot-password",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}password_reset/`,
        data
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
