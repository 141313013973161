import { Button, Input } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../redux/services";
import LoadingSpinner from "../../assets/loading-spinner.gif";

const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isPasswordMatching, setIsPasswordMatching] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetPasswordConfirmStatus, resetPasswordError } = useSelector(
    (state) => state.auth
  );

  const submitHandler = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      dispatch(
        resetPassword({
          token,
          password: confirmPassword,
        })
      );
    } else {
      setIsPasswordMatching(false);
    }
  };

  if (resetPasswordConfirmStatus === "idle") {
    return (
      <div className="w-96 mx-auto mt-8">
        <h1 className="text-lg text-center font-medium">Reset Password</h1>
        <p>Enter a new password below to change your password.</p>
        <form onSubmit={submitHandler} className="">
          <div className="mt-2">
            <Input.Password
              type="password"
              placeholder="Enter new password"
              className={`${
                isPasswordMatching === false
                  ? "border-red-300"
                  : "border-slate-700"
              } border  w-full outline-0 p-1`}
              name="new password"
              required
              onFocus={() => setIsPasswordMatching(true)}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
          <div className="mt-1">
            <Input.Password
              type="password"
              placeholder="Confirm new password"
              className={`${
                isPasswordMatching === false
                  ? "border-red-300"
                  : "border-slate-700"
              } border  w-full outline-0 p-1`}
              name="confirm password"
              onFocus={() => setIsPasswordMatching(true)}
              required
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>

          {!isPasswordMatching && (
            <p className="text-red-500">Passwords does not matched.</p>
          )}
          <Button
            type="primary"
            htmlType="submit"
            className="block mt-3 mx-auto"
          >
            Submit
          </Button>
        </form>
      </div>
    );
  } else if (resetPasswordConfirmStatus === "success") {
    return (
      <div className="mt-8">
        <p className="text-lg w-fit mx-auto">
          Password has been reset successfully. Go back to login.
        </p>
        <Button
          type="primary"
          className="block rounded mt-3 mx-auto"
          onClick={() => navigate("/")}
        >
          Go to Login Page
        </Button>
      </div>
    );
  } else if (resetPasswordConfirmStatus === "failed") {
    return (
      <div className="mt-8">
        <p className="w-fit mx-auto text-center">
          {resetPasswordError.password
            ? resetPasswordError.password[0]
            : "The link has been expired. Please go to the forgot password to get the valid link."}
        </p>
        <Button
          type="primary"
          className="block mt-4 mx-auto"
          onClick={() => navigate("/forgot-password")}
        >
          Go to forgot password
        </Button>
      </div>
    );
  } else if (resetPasswordConfirmStatus === "loading") {
    return (
      <div className="h-full flex items-center justify-center">
        <div>
          <img
            className="w-[22rem]"
            src={LoadingSpinner}
            alt="Loading spinner"
          />
        </div>
      </div>
    );
  }
};

export { ResetPassword };
