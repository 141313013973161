import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSlotsByDate } from "../../utils/getSlotsByDate";
import { getAdminRequests } from "../../redux/services";
import { RiCloseCircleFill } from "react-icons/ri";
import { DeleteModal } from "./delete-modal/DeleteModal";
import LoadingSpinner from "../../assets/loading-spinner.gif";
import { getCoachConfirmedSlots } from "../../redux/services/confirm-slots/confirmSlotsPerRequest";
import { daysArray } from "../../utils/constants";
import { sortTime } from "../../utils";
import { getBookedSessions } from "../../redux/services/confirm-slots/getBookedSessions";

const EditSlots = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [deleteSlotDetails, setDeleteSlotDetails] = useState(null);
  const {
    confirmedSlots,
    fetchingConfirmedSlotStatus,
    fetchingBookedSessions,
    bookedSessions,
  } = useSelector((state) => state.confirmSlots);

  const { requestsList, fetchingAdminRequest } = useSelector(
    (state) => state.adminRequest
  );

  let activeRequestIds = [];
  let activeRequestSlots = [];
  if (fetchingAdminRequest === "success") {
    requestsList.forEach((request) => {
      if (request.isActive === true) {
        activeRequestIds.push(request.id);
      }
    });
  }

  if (
    fetchingAdminRequest === "success" &&
    fetchingConfirmedSlotStatus === "success"
  ) {
    confirmedSlots.forEach((slot) => {
      if (activeRequestIds.includes(+slot.request_ID)) {
        activeRequestSlots.push(slot);
      }
    });
  }

  // ṛeturning either the upcoming slot or the past slot based on the slot type state
  const slotsToShow = activeRequestSlots.filter(
    (slot) => +slot.start_time > +new Date().getTime()
  );

  // formatting data in respect to dates
  let confirmedFormattedData = getSlotsByDate(slotsToShow);
  const allDates = Object.keys(confirmedFormattedData);
  // sorting dates
  const sortedDates = allDates.sort();
  // sorting slots based on start time
  confirmedFormattedData = sortTime(sortedDates, confirmedFormattedData);

  useEffect(() => {
    dispatch(getAdminRequests());
    dispatch(getCoachConfirmedSlots(authState.user.id));
    dispatch(getBookedSessions(authState.user.id));
  }, [dispatch, authState.token, authState.user.id]);

  // return array of slot ids for slots booked by learner
  const bookedSessionIds = bookedSessions.map((session) => session.slot.id);

  if (
    fetchingAdminRequest === "success" &&
    fetchingConfirmedSlotStatus === "success" &&
    fetchingBookedSessions === "success"
  ) {
    if (activeRequestSlots.length === 0) {
      return (
        <div className="px-4 mt-6 mb-2 text-center font-semibold text-lg">
          No slots available to edit.
        </div>
      );
    } else {
      return (
        <>
          <div className="px-4 mt-6 mb-2 font-semibold text-lg">
            Confirmed slots from active request:{" "}
          </div>
          <p className="px-4 mb-2">
            You can only delete your slots from an active request. To make any
            other changes reach out to{" "}
            <a
              className="text-sky-600 font-semibold"
              href="mailto:pmo@meeraq.com"
            >
              pmo@meeraq.com
            </a>
          </p>
          <div className="px-2">
            {sortedDates.map((slotKey, index) => {
              return (
                <div key={index} className="mb-6 w-[97%] mx-auto">
                  <div className="font-semibold text-md text-slate-800 py-3 px-4 rounded bg-gray-200">
                    {`${slotKey.split("-")[2]}-${slotKey.split("-")[1]}-${
                      slotKey.split("-")[0]
                    }`}{" "}
                    <div className="inline-block relative">
                      ({daysArray[new Date(slotKey).getDay()]})
                      <span className="rounded-full absolute px-2 -top-2 -right-5 bg-red-400 text-xs text-white">
                        {confirmedFormattedData[slotKey]?.length}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-3 my-2 mx-auto flex-wrap flex-row w-[95%]">
                    {confirmedFormattedData[slotKey].map((slot, idx) => {
                      return (
                        <div key={idx} className="relative">
                          <span
                            className={`border px-6 py-2 inline-block tracking-tight relative  ${
                              bookedSessionIds.includes(slot.id)
                                ? "bg-success"
                                : "bg-sky-300"
                            }`}
                          >
                            {new Date(+slot.start_time).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                            -
                            {new Date(+slot.end_time).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </span>
                          {!bookedSessionIds.includes(slot.id) && (
                            <RiCloseCircleFill
                              className="cursor-pointer -right-1 -top-1 text-xl absolute"
                              onClick={() => {
                                setDeleteSlotDetails({
                                  getCoachConfirmedSlots,
                                  coach_id: authState.user.id,
                                  deleteSlot: slot,
                                });
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          {deleteSlotDetails && (
            <DeleteModal
              deleteSlotDetails={deleteSlotDetails}
              setDeleteSlotDetails={setDeleteSlotDetails}
            />
          )}
        </>
      );
    }
  } else if (
    fetchingAdminRequest === "loading" &&
    fetchingConfirmedSlotStatus === "loading" &&
    fetchingBookedSessions === "loading"
  ) {
    return (
      <div className="px-4 mt-6 mb-2 font-semibold text-lg">
        <div className="flex items-center justify-center">
          <img
            className="w-[22rem]"
            src={LoadingSpinner}
            alt="Loading Spinner"
          />
        </div>
      </div>
    );
  } else {
    <div className="px-4 mt-6 mb-2 text-center">
      Error in loading Slots. Please try reloading the page.
    </div>;
  }
};
export { EditSlots };
