import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const login = createAsyncThunk(
  "auth/login",
  async (loginData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN}`,
        { ...loginData, type: "coach" }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
