import { Button } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirmSlotsPerRequest, getCoachConfirmedSlots } from "../../../redux/services/confirm-slots/confirmSlotsPerRequest";

const ConfirmSlotModal = ({
  confirmSlotModalDetails,
  setConfirmSlotModalDetails,
}) => {
  const { selectedSlots, selectedRequestId } = confirmSlotModalDetails;
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const confirmSlotHandler = () => {
    dispatch(
      confirmSlotsPerRequest({
        token: authState.token,
        coachId: authState.user.id,
        requestId: selectedRequestId,
        slots: selectedSlots,
        getCoachConfirmedSlots
      })
    );
  };

  return ReactDOM.createPortal(
    <>
      <div className="fixed inset-0 z-modal-bg bg-slate-300 opacity-70" />
      <section className="py-4 flex flex-col gap-2 px-8 z-modal w-11/12 sm:w-9/12 lg:w-2/3 xl:w-1/3 border border-zinc-400 rounded fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white">
        <h2 className="text-lg font-medium ">
          {selectedSlots.length > 0
            ? "Are you sure you want to confirm your selected slots?"
            : "You haven't picked any slot. Are you sure you don't want to pick any slots from this request?"}
        </h2>
        <div className="mt-2 flex gap-4 justify-end">
          <Button type="primary" onClick={confirmSlotHandler}>
            Confirm
          </Button>
          <Button
            type="secondary"
            onClick={() => setConfirmSlotModalDetails(null)}
          >
            Cancel
          </Button>
        </div>
      </section>
    </>,
    document.getElementById("portal")
  );
};

export { ConfirmSlotModal };
