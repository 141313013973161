import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCoachConfirmedSlots } from "../../redux/services/confirm-slots/confirmSlotsPerRequest";
import { getSlotsByDate } from "../../utils/getSlotsByDate";
import LoadingSpinner from "../../assets/loading-spinner.gif";
import { Link } from "react-router-dom";
import { daysArray } from "../../utils/constants";
import { sortTime } from "../../utils";
import { getBookedSessions } from "../../redux/services/confirm-slots/getBookedSessions";

const ConfirmedSlot = () => {
  const [slotsType, setSlotsType] = useState("UPCOMING");

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const {
    fetchingConfirmedSlotStatus,
    confirmedSlots,
    fetchingBookedSessions,
    bookedSessions,
  } = useSelector((state) => state.confirmSlots);

  useEffect(() => {
    dispatch(getCoachConfirmedSlots(authState.user.id));
    dispatch(getBookedSessions(authState.user.id));
  }, [dispatch, authState.user.id]);

  // ṛeturning either the upcoming slot or the past slot based on the slot type state
  const slotsToShow =
    slotsType === "UPCOMING"
      ? confirmedSlots.filter(
          (slot) => +slot.start_time > +new Date().getTime()
        )
      : bookedSessions
          .map((bookedSession) => bookedSession.slot)
          .filter((slot) => +slot.start_time < +new Date().getTime()); // only showing the past booked slots

  // formatting data in respect to dates
  let formattedData = getSlotsByDate(slotsToShow);
  const allDates = Object.keys(formattedData);
  // sorting dates
  const sortedDates = allDates.sort();
  // sorting slots based on start time
  formattedData = sortTime(sortedDates, formattedData);

  // return array of slot ids for slots booked by learner
  const bookedSessionIds = bookedSessions.map((session) => session.slot.id);
  if (
    fetchingConfirmedSlotStatus === "success" &&
    fetchingBookedSessions === "success"
  ) {
    if (confirmedSlots.length === 0) {
      return (
        <div className="px-4 mt-6 mb-2 text-center font-semibold text-lg">
          You haven't confirmed any slots yet. Please visit{" "}
          <Link className="underline text-sky-500" to="/availability">
            Slot request
          </Link>{" "}
          page to confirm the slots
        </div>
      );
    } else {
      return (
        <>
          <div className="mx-4 mt-6 mb-4 flex gap-2 border-b">
            <div
              onClick={() => setSlotsType("UPCOMING")}
              className={`cursor-pointer pt-2 pb-1 w-[50%] text-center font-semibold ${
                slotsType === "UPCOMING"
                  ? "bg-tab text-white hover:bg-tab "
                  : "bg-tab-bg-inactive hover:bg-tab-bg-inactive-hover"
              }`}
            >
              Upcoming Provided Slots
            </div>
            <div
              onClick={() => setSlotsType("PAST")}
              className={`cursor-pointer pt-2 pb-1 w-[50%] text-center font-semibold ${
                slotsType === "PAST"
                  ? "bg-tab text-white hover:bg-tab "
                  : "bg-tab-bg-inactive hover:bg-tab-bg-inactive-hover"
              }`}
            >
              Past Confirmed Slots
            </div>
          </div>
          <div className="px-2">
            {sortedDates.length === 0 && (
              <p className="px-4 text-center font-semibold">
                No {slotsType.toLowerCase()} slots available to show.
              </p>
            )}
            {sortedDates.map((slotKey, index) => {
              return (
                <div key={index} className="mb-6 w-[97%] mx-auto">
                  <div className="font-semibold text-slate-800 px-4 py-3 rounded bg-gray-200">
                    {`${slotKey.split("-")[2]}-${slotKey.split("-")[1]}-${
                      slotKey.split("-")[0]
                    }`}{" "}
                    <div className="inline-block relative">
                      ({daysArray[new Date(slotKey).getDay()]})
                      <span className="rounded-full absolute px-2 -top-2 -right-5 bg-red-400 text-xs text-white">
                        {formattedData[slotKey].length}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-3 my-2 mx-auto flex-wrap w-[95%]">
                    {formattedData[slotKey].map((slot, idx) => {
                      return (
                        <div key={idx}>
                          <span
                            className={`border px-6 py-2 inline-block tracking-tight ${
                              bookedSessionIds.includes(slot.id)
                                ? "bg-success"
                                : "bg-sky-300"
                            }`}
                          >
                            {new Date(+slot.start_time).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                            -
                            {new Date(+slot.end_time).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    }
  } else if (
    fetchingConfirmedSlotStatus === "loading" &&
    fetchingBookedSessions === "loading"
  ) {
    return (
      <div className="px-4 mt-6 mb-2 font-semibold text-lg">
        <div className="flex items-center justify-center">
          <img
            className="w-[22rem]"
            src={LoadingSpinner}
            alt="Loading Spinner"
          />
        </div>
      </div>
    );
  } else if (
    fetchingConfirmedSlotStatus === "failed" ||
    fetchingBookedSessions === "failed"
  ) {
    return (
      <div className="px-4 mt-6 mb-2 text-center font-semibold">
        Error in loading Slots. Please try reloading the page.
      </div>
    );
  }
};

export { ConfirmedSlot };
