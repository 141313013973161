import React from "react";
import MeeraqLogo from "../../assets/meeraq_logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/authSlice";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Header = ({ setShowNav, showNav, hideSideNav }) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <header className="px-6 py-1 h-16 flex bg-white flex-wrap items-center shadow sticky top-0 justify-between z-[10] ">
      <img className="w-40 mt-2" src={MeeraqLogo} alt="Meeraq Logo" />
      {authState.isLoggedIn && (
        <div className="flex items-center gap-4 text-header">
          {!hideSideNav && (
            <>
              <p className="hidden md:block font-semibold">
                Welcome, {authState.user.firstName[0].toUpperCase()}
                {authState.user.firstName.slice(
                  1,
                  authState.user.firstName.length
                )}
                !
              </p>
              <Button
                type="secondary"
                className={"hidden sm:block cursor-pointer"}
                onClick={() => {
                  dispatch(logout());
                  navigate("/");
                }}
              >
                Logout
              </Button>{" "}
              <button
                className="text-3xl block md:hidden"
                onClick={() => setShowNav(!showNav)}
              >
                <GiHamburgerMenu />
              </button>
            </>
          )}
        </div>
      )}
    </header>
  );
};

export { Header };
