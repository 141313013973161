import uuid from "react-uuid";

export const daysArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const emptySlotData = {
  Monday: [
    {
      id: uuid(),
      start_time: "",
      end_time: "",
    },
  ],
  Tuesday: [
    {
      id: uuid(),
      start_time: "",
      end_time: "",
    },
  ],
  Wednesday: [
    {
      id: uuid(),
      start_time: "",
      end_time: "",
    },
  ],
  Thursday: [
    {
      id: uuid(),
      start_time: "",
      end_time: "",
    },
  ],
  Friday: [
    {
      id: uuid(),
      start_time: "",
      end_time: "",
    },
  ],
  Saturday: [
    {
      id: uuid(),
      start_time: "",
      end_time: "",
    },
  ],
  Sunday: [
    {
      id: uuid(),
      start_time: "",
      end_time: "",
    },
  ],
};

export const dummySlotData = {
  selectedDay: "Monday",
  bookedSlots: {
    Monday: [
      {
        startTimeID: "57",
        endTimeID: "60",
      },
    ],
    Tuesday: [
      {
        startTimeID: "65",
        endTimeID: "73",
      },
      {
        startTimeID: "77",
        endTimeID: "85",
      },
    ],
    Wednesday: [],
    Thursday: [
      {
        startTimeID: "67",
        endTimeID: "75",
      },
    ],
    Friday: [],
    Saturday: [],
    Sunday: [],
  },
};

export const sessions = [
  {
    date: "01/01/2022",
    day: "Monday",
    time: "12:30 PM - 01:15 PM",
    learnerName: "XYZ",
    courseName: "Emotional Intelligence",
    link: "/#",
  },
  {
    date: "01/01/2022",
    day: "Monday",
    time: "12:30 PM - 01:15 PM",
    learnerName: "XYZ",
    courseName: "Emotional Intelligence",
    link: "/#",
  },
  {
    date: "01/01/2022",
    day: "Monday",
    time: "12:30 PM - 01:15 PM",
    learnerName: "XYZ",
    courseName: "Emotional Intelligence",
    link: "/#",
  },
  {
    date: "01/01/2022",
    day: "Monday",
    time: "12:30 PM - 01:15 PM",
    learnerName: "XYZ",
    courseName: "Emotional Intelligence",
    link: "/#",
  },
];

export const time = {
  1: "00:00 AM",
  2: "00:15 AM",
  3: "00:30 AM",
  4: "00:45 AM",
  5: "01:00 AM",
  6: "01:15 AM",
  7: "01:30 AM",
  8: "01:45 AM",
  9: "02:00 AM",
  10: "02:15 AM",
  11: "02:30 AM",
  12: "02:45 AM",
  13: "03:00 AM",
  14: "03:15 AM",
  15: "03:30 AM",
  16: "03:45 AM",
  17: "04:00 AM",
  18: "04:15 AM",
  19: "04:30 AM",
  20: "04:45 AM",
  21: "05:00 AM",
  22: "05:15 AM",
  23: "05:30 AM",
  24: "05:45 AM",
  25: "06:00 AM",
  26: "06:15 AM",
  27: "06:30 AM",
  28: "06:45 AM",
  29: "07:00 AM",
  30: "07:15 AM",
  31: "07:30 AM",
  32: "07:45 AM",
  33: "08:00 AM",
  34: "08:15 AM",
  35: "08:30 AM",
  36: "08:45 AM",
  37: "09:00 AM",
  38: "09:15 AM",
  39: "09:30 AM",
  40: "09:45 AM",
  41: "10:00 AM",
  42: "10:15 AM",
  43: "10:30 AM",
  44: "10:45 AM",
  45: "11:00 AM",
  46: "11:15 AM",
  47: "11:30 AM",
  48: "11:45 AM",
  49: "12:00 PM",
  50: "12:15 PM",
  51: "12:30 PM",
  52: "12:45 PM",
  53: "01:00 PM",
  54: "01:15 PM",
  55: "01:30 PM",
  56: "01:45 PM",
  57: "02:00 PM",
  58: "02:15 PM",
  59: "02:30 PM",
  60: "02:45 PM",
  61: "03:00 PM",
  62: "03:15 PM",
  63: "03:30 PM",
  64: "03:45 PM",
  65: "04:00 PM",
  66: "04:15 PM",
  67: "04:30 PM",
  68: "04:45 PM",
  69: "05:00 PM",
  70: "05:15 PM",
  71: "05:30 PM",
  72: "05:45 PM",
  73: "06:00 PM",
  74: "06:15 PM",
  75: "06:30 PM",
  76: "06:45 PM",
  77: "07:00 PM",
  78: "07:15 PM",
  79: "07:30 PM",
  80: "07:45 PM",
  81: "08:00 PM",
  82: "08:15 PM",
  83: "08:30 PM",
  84: "08:45 PM",
  85: "09:00 PM",
  86: "09:15 PM",
  87: "09:30 PM",
  88: "09:45 PM",
  89: "10:00 PM",
  90: "10:15 PM",
  91: "10:30 PM",
  92: "10:45 PM",
  93: "11:00 PM",
  94: "11:15 PM",
  95: "11:30 PM",
  96: "11:45 PM",
};

export const navLinks = [
  // { name: "Book Slots", path: "book-slots" },
  // { name: "Review Slots", path: "review-slots" },
  // { name: "View Sessions", path: "view-sessions" },
  { name: "Slot Requests", path: "availability" },
  { name: "Provided Slots Summary", path: "selected-slots" },
  { name: "Delete Slots", path: "edit-slots" },
  { name: "Confirmed Sessions", path: "confirmed-sessions" },
  { name: "Help", path: "help" },
  // { name: "Service Approvals", path: "service-approval" },
];
