export const getSlotsByDate = (slots) => {
  let slotsByDate = {};
  slots.forEach((slot) => {
    if (slotsByDate[slot.date]) {
      slotsByDate[slot.date] = [
        ...slotsByDate[slot.date],
        {
          ...slot,
        },
      ];
    } else {
      slotsByDate[slot.date] = [
        {
          ...slot,
        },
      ];
    }
  });

  return slotsByDate;
};
