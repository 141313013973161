import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAdminRequests = createAsyncThunk(
  "admin-request",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ADMIN_REQUESTS}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
