import { format, parseISO } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import {
  convertTimeStampTo12HourString,
  getSlotsPerRequestByDate,
  sortTime,
} from "../../utils";
import { RequestTypeActive } from "./RequestTypeActive";
import { RequestTypeNew } from "./RequestTypeNew";

const RequestRow = ({
  requestId: selectedRequestId,
  requestType,
  isExpanded,
  setIsExpanded,
}) => {
  const { availableSlots, confirmedSlots } = useSelector(
    (state) => state.confirmSlots
  );

  let confirmedSlotsPerRequestByDate = getSlotsPerRequestByDate(
    confirmedSlots,
    selectedRequestId
  );
  const allDates = Object.keys(confirmedSlotsPerRequestByDate);
  // sorting dates
  const sortedDates = allDates.sort();
  // sorting slots based on start time
  confirmedSlotsPerRequestByDate = sortTime(
    sortedDates,
    confirmedSlotsPerRequestByDate
  );

  const { requestsList: allRequests, fetchingAdminRequest } = useSelector(
    (state) => state.adminRequest
  );

  let selectedRequestData;
  if (fetchingAdminRequest === "success") {
    selectedRequestData = allRequests.find(
      (request) => Number(request.id) === Number(selectedRequestId)
    );
  }

  const request = allRequests.find(
    (request) => Number(request.id) === Number(selectedRequestId)
  );

  return (
    <>
      <tr key="main" className="border">
        <td className="py-2 pl-2 border ">{request?.name}</td>
        <td className="py-2 pl-2 border ">
          {format(parseISO(request?.expire_date), "dd-MM-yyyy")}
        </td>
        {isExpanded === selectedRequestId ? (
          <td
            className="pl-2 text-center cursor-pointer"
            onClick={() => setIsExpanded(null)}
          >
            <span className="text-blue-600 hover:underline">View less</span>
          </td>
        ) : (
          <td
            className="pl-2  cursor-pointer text-center"
            onClick={() => setIsExpanded(selectedRequestId)}
          >
            <span className="text-blue-600 hover:underline">Click here.</span>
          </td>
        )}
      </tr>
      {isExpanded === selectedRequestId && (
        <tr className="expandable bg-zinc-100" key="tr-expander">
          <td colSpan={3}>
            <>
              {requestType === "NEW" && (
                <RequestTypeNew
                  selectedRequestId={selectedRequestId}
                  requestType={requestType}
                  confirmedSlotsPerRequestByDate={
                    confirmedSlotsPerRequestByDate
                  }
                />
              )}

              {requestType === "ACTIVE" && (
                <RequestTypeActive
                  requestType={requestType}
                  selectedRequestId={selectedRequestId}
                  confirmedSlotsPerRequestByDate={
                    confirmedSlotsPerRequestByDate
                  }
                />
              )}

              {requestType === "PAST" && (
                <section className="grow w-[97%] mt-4 mb-8 mx-auto">
                  {selectedRequestId &&
                    (Object.keys(confirmedSlotsPerRequestByDate).length > 0 ? (
                      <>
                        <p className="mb-2">Slots you booked : </p>
                        {Object.keys(confirmedSlotsPerRequestByDate).map(
                          (date) => {
                            let myDate = date.split("-");
                            var formattedDates = new Date(
                              `${myDate[0]}-${myDate[1]}-${myDate[2]}`
                            );
                            return (
                              <div>
                                <h3 className="font-bold text-slate-800 px-4 py-2 rounded bg-slate-300">
                                  {format(formattedDates, "dd-MM-yyyy (EEEE)")} :
                                </h3>
                                <div className="flex flex-wrap w-[97%] mx-auto gap-3 my-2">
                                  {confirmedSlotsPerRequestByDate[date].map(
                                    (slot) => (
                                      <span
                                        key={slot.id}
                                        className={`border p-2 inline-block bg-sky-300`}
                                      >
                                        {convertTimeStampTo12HourString(
                                          slot,
                                          "start_time"
                                        )}{" "}
                                        -{" "}
                                        {convertTimeStampTo12HourString(
                                          slot,
                                          "end_time"
                                        )}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <p className="my-6 text-center">
                        You didn't book any slot.
                      </p>
                    ))}
                </section>
              )}
              {requestType === "MISSED" && (
                <section className="w-[95%] mx-auto">
                  {fetchingAdminRequest === "success" && (
                    <>
                      <p className="my-2">
                        Expired on:{" "}
                        <span className="font-semibold">
                          {format(
                            new Date(selectedRequestData?.expire_date),
                            "dd-MM-yyyy"
                          )}
                          
                        </span>
                      </p>
                      <p className="mb-2">
                        Slots requested for dates:{" "}
                        <div className="inline-flex">
                          {Object.keys(
                            getSlotsPerRequestByDate(
                              availableSlots,
                              selectedRequestId
                            )
                          ).map((date, index) => (
                            <span className="font-semibold">
                              {index === 0
                                ? format(new Date(date), "dd-MM-yyyy")
                                : `, ${format(new Date(date), "dd-MM-yyyy")}`}
                            </span>
                          ))}
                        </div>
                      </p>
                    </>
                  )}
                </section>
              )}
            </>
          </td>
        </tr>
      )}
    </>
  );
};

export { RequestRow };
