import { Button } from "antd/lib/radio";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { logout } from "../../redux/reducers/authSlice";
import { navLinks } from "../../utils/constants";

const SideNav = ({ showNav, setShowNav }) => {
  const dispatch = useDispatch();

  const getActiveClassName = ({ isActive }) => {
    return (
      (isActive
        ? "text-aside-bar-text bg-aside-bar-bg hover:text-white"
        : "text-slate-700 hover:bg-tab-bg-inactive-hover hover:text-inherit") +
      " p-2 block font-semibold px-4 transition-none md:rounded"
    );
  };

  useEffect(() => {
    if (showNav) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [showNav]);

  return (
    <aside
      className={
        "w-56 ml-auto z-[4] absolute right-0 top-13 h-[91.5vh] col-start-1 col-end-3 shadow-[0px_4px_10px_0px_rgba(0,0,0,0.3)] bg-white md:w-full md:sticky md:left-0 md:top-[4.1rem] md:mt-1 md:block " +
        (showNav ? "block h-full" : "hidden")
      }
    >
      <ul className="py-4 md:w-[90%] md:mx-auto">
        {navLinks.map((link) => {
          return (
            <li className="mb-2" onClick={() => setShowNav(false)}>
              <NavLink to={link.path} className={getActiveClassName}>
                {link.name}
              </NavLink>
            </li>
          );
        })}
        <Button
          type="secondary"
          className="sm:hidden block mx-auto border-0 cursor-pointer text-slate-700 hover:bg-tab-bg-inactive-hover hover:text-inherit font-semibold px-4"
          onClick={() => {
            dispatch(logout());
            setShowNav(false);
          }}
        >
          Logout
        </Button>
      </ul>
    </aside>
  );
};

export { SideNav };
