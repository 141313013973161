import { Button, DatePicker, Table, Tag } from "antd";
import axios from "axios";
import { format } from "date-fns";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingSpinner from "../../assets/loading-spinner.gif";

const columns = [
  {
    title: "Service Approval ID",
    dataIndex: "ref_id",
    key: "ref_id",
  },
  {
    title: "No. of sessions",
    dataIndex: "total_no_of_sessions",
    key: "total_no_of_sessions",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (_, rowData) => rowData.fees * rowData.total_no_of_sessions,
  },
  {
    title: "Created",
    dataIndex: "generated_date",
    key: "generated_date",
  },
  {
    title: "Generated For",
    dataIndex: "generate_for_month_and_year",
    key: "generate_for_month_and_year",
    render: (_, rowData) => (
      <span className="capitalize">
        {rowData.generate_for_month + " " + rowData.generate_for_year}
      </span>
    ),
  },
  {
    title: "Status",
    dataIndex: "is_approved",
    key: "is_approved",
    render: (_, rowData) => {
      if (rowData.is_approved === "null") {
        return <Tag color="default">Pending</Tag>;
      } else if (rowData.is_approved === "true") {
        return <Tag color="success">Approved </Tag>;
      } else if (rowData.is_approved === "false") {
        return <Tag color="error">Rejected</Tag>;
      }
    },
  },
  {
    title: "Responded On",
    dataIndex: "response_by_finance_date",
    key: "response_by_finance_date",
  },
  {
    title: "Invoice No.",
    dataIndex: "invoice_no",
    key: "invoice_no",
  },
];

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const columnsForCoachingSessions = [
  {
    title: "Session Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Session Time",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "Learner Name",
    dataIndex: "learnerName",
    key: "learnerName",
  },
  {
    title: "Learner Email",
    dataIndex: "learnerEmail",
    key: "learnerEmail",
  },
];

const initialSelectedDate = {
  month: "",
  year: "",
  date: "",
};

const getServiceApprovals = async ({ setServiceApprovals, coach_id }) => {
  setServiceApprovals({ loading: true, error: false, data: [] });
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}service-approval-coach/${coach_id}`,
    });
    if (response.status === 200) {
      setServiceApprovals({
        loading: false,
        error: false,
        data: response.data.data,
      });
    }
  } catch (err) {
    setServiceApprovals({
      loading: false,
      error: true,
      data: [],
    });
  }
};

const handleCreateServiceApproval = async ({
  data,
  setCreateCoachingSessionStatus,
  setIsCreatingNewApproval,
  setSelectedDate,
  setIsServiceApprovalAlreadyExist,
  setShowCoachingSessions,
  setServiceApprovals,
}) => {
  setCreateCoachingSessionStatus("loading");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add-service-approval/`,
      data
    );
    if (response.status === 201) {
      setCreateCoachingSessionStatus("idle");
      setIsCreatingNewApproval(false);
      setSelectedDate(initialSelectedDate);
      setIsServiceApprovalAlreadyExist(false);
      setShowCoachingSessions(false);
      toast.success("Service approval created successfully");
      getServiceApprovals({ setServiceApprovals, coach_id: data.coach_id });
    }
  } catch (err) {
    toast.error("Failed to create service approval");
  }
};

export const ServiceApprovals = () => {
  const [isCreatingNewApproval, setIsCreatingNewApproval] = useState(false);
  const [serviceApprovals, setServiceApprovals] = useState({
    error: false,
    loading: true,
    data: [],
  });

  const {
    user: { id: coach_id },
  } = useSelector((state) => state.auth);

  useEffect(() => {
    getServiceApprovals({ setServiceApprovals, coach_id });
  }, [coach_id]);

  if (isCreatingNewApproval === false) {
    return (
      <div>
        <p className="py-4 text-center">
          <Button type="primary" onClick={() => setIsCreatingNewApproval(true)}>
            Create new Service Approval
          </Button>
        </p>
        {serviceApprovals.error && !serviceApprovals.loading && (
          <p className="text-center text-gray-400 font-semibold">
            Failed to load service approvals
          </p>
        )}

        {!serviceApprovals.error && serviceApprovals.loading && (
          <div className="px-4 mt-6 mb-2 font-semibold text-lg">
            <div className="flex items-center justify-center">
              <img
                className="w-[22rem]"
                src={LoadingSpinner}
                alt="Loading Spinner"
              />
            </div>
          </div>
        )}

        {!serviceApprovals.error && !serviceApprovals.loading && (
          <Table
            className="mx-4 border-2 p-2"
            dataSource={serviceApprovals.data}
            columns={columns}
          />
        )}
      </div>
    );
  } else {
    return (
      <CreateServiceApproval
        setServiceApprovals={setServiceApprovals}
        serviceApprovals={serviceApprovals}
        setIsCreatingNewApproval={setIsCreatingNewApproval}
      />
    );
  }
};

// getting coaching sessions for selected month and year

const getCoachingSessions = async ({ date, coach_id, setCoachingSessions }) => {
  setCoachingSessions({
    loading: "loading",
    error: false,
    data: [],
  });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}get-coaching-sessions-of-coach-by-month/`,
      {
        date,
        coach: coach_id,
      }
    );
    if (response.status === 200) {
      setCoachingSessions({
        loading: "success",
        error: false,
        data: response.data.data,
      });
    }
    console.log("coaching sessions by month", response);
  } catch (err) {
    setCoachingSessions({
      loading: "failed",
      error: true,
      data: [],
    });
  }
};

// component for creating service approval
function CreateServiceApproval({
  serviceApprovals,
  setIsCreatingNewApproval,
  setServiceApprovals,
}) {
  const [showCoachingSessions, setShowCoachingSessions] = useState(false);
  const [isServiceApprovalAlreadyExist, setIsServiceApprovalAlreadyExist] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [coachingSessions, setCoachingSessions] = useState({
    loading: "idle",
    error: false,
    data: [],
  });

  const {
    user: { id: coach_id },
  } = useSelector((state) => state.auth);

  const serviceApprovalIdForSelectedDate = serviceApprovals.data.find(
    (serviceApproval) =>
      serviceApproval.generate_for_month === selectedDate.month &&
      serviceApproval.generate_for_year === String(selectedDate.year)
  );

  useEffect(() => {
    if (selectedDate.date) {
      if (serviceApprovalIdForSelectedDate) {
        setCoachingSessions({
          loading: "idle",
          error: false,
          data: [],
        });
        setIsServiceApprovalAlreadyExist(true);
      } else {
        getCoachingSessions({
          date: `${months.indexOf(selectedDate.month) + 1}-${
            selectedDate.year
          }`,
          coach_id,
          setCoachingSessions,
        });
      }
    }
  }, [selectedDate, serviceApprovalIdForSelectedDate, coach_id]);

  const onChange = (date) => {
    setIsServiceApprovalAlreadyExist(false);
    date !== null
      ? setSelectedDate((prev) => ({
          month: months[date.month()],
          year: date.year(),
          date: date,
        }))
      : setSelectedDate(initialSelectedDate);
  };

  const coachingSessionListForTable = [];

  coachingSessions.data.forEach((confirmedSession) => {
    coachingSessionListForTable.push({
      learnerName: confirmedSession.name,
      learnerEmail: confirmedSession.email,
      time: format(
        new Date(+confirmedSession.slot.start_time),
        "h:mm a"
      ).toUpperCase(),
      endTime: format(new Date(+confirmedSession.slot.end_time), "h:mm a"),
      date: format(new Date(confirmedSession.slot.date), "dd-MM-yyyy"),
    });
  });

  return (
    <section className="m-4">
      <header className="bg-gray-100 p-2 relative">
        <p className="font-semibold md:text-center">New Service Approval</p>
        <Button
          type="danger"
          className="text-red-500 absolute right-[0] top-[2px]"
          onClick={() => {
            setIsCreatingNewApproval(false);
            setSelectedDate(initialSelectedDate);
            setIsServiceApprovalAlreadyExist(false);
            setShowCoachingSessions(false);
          }}
        >
          Cancel
        </Button>
      </header>
      <section className="py-2 flex items-center justify-center">
        Month and Year:{"  "}
        <DatePicker
          className="w-[12rem] ml-2"
          placeholder="Select Month and Year"
          value={selectedDate.date}
          onChange={onChange}
          picker="month"
        />
      </section>

      {isServiceApprovalAlreadyExist && (
        <section className="pb-2 text-red-500">
          <p className="text-center">
            Service Approval have been already generated for{" "}
            <span className="capitalize">
              {selectedDate.month} {selectedDate.year}
            </span>{" "}
            with Id :{" "}
            <span
              className="
							
							font-semibold"
            >
              {serviceApprovalIdForSelectedDate._id}
            </span>
          </p>
        </section>
      )}

      {coachingSessions.loading === "loading" && (
        <div className="px-4 mt-6 mb-2 font-semibold text-lg">
          <div className="flex items-center justify-center">
            <img
              className="w-[22rem]"
              src={LoadingSpinner}
              alt="Loading Spinner"
            />
          </div>{" "}
        </div>
      )}

      {coachingSessions.loading === "failed" && (
        <p className="text-gray-400 font-semibold text-center">
          Failed to load coaching session details
        </p>
      )}

      {coachingSessions.loading === "success" &&
        coachingSessions.data.length === 0 && (
          <p className="text-gray-400 font-semibold text-center">
            You didn't took any session in{" "}
            <span className="capitalize">
              {selectedDate.month} {selectedDate.year}
            </span>
          </p>
        )}

      {coachingSessions.loading === "success" &&
        coachingSessions.data.length > 0 && (
          <>
            <InvoiceWrapper
              coachingSessionsList={coachingSessions.data}
              selectedDate={selectedDate}
              setIsCreatingNewApproval={setIsCreatingNewApproval}
              setSelectedDate={setSelectedDate}
              setServiceApprovals={setServiceApprovals}
              setIsServiceApprovalAlreadyExist={
                setIsServiceApprovalAlreadyExist
              }
              setShowCoachingSessions={setShowCoachingSessions}
            />
            {showCoachingSessions && (
              <section className="mt-4">
                <header className="bg-gray-100 p-2 relative">
                  <p className="font-semibold md:text-center">
                    Coaching sessions done in{" "}
                    {selectedDate.month + " " + selectedDate.year}{" "}
                  </p>
                  <Button
                    type="danger"
                    className="text-red-500 absolute right-[0] top-[2px]"
                    onClick={() => setShowCoachingSessions(false)}
                  >
                    Hide Sessions
                  </Button>
                </header>
                <Table
                  className="p-2"
                  columns={columnsForCoachingSessions}
                  dataSource={coachingSessionListForTable}
                />
              </section>
            )}
          </>
        )}
    </section>
  );
}

// invoice wrapper component
function InvoiceWrapper({
  selectedDate,
  setShowCoachingSessions,
  coachingSessionsList,
  setIsCreatingNewApproval,
  setSelectedDate,
  setIsServiceApprovalAlreadyExist,
  setServiceApprovals,
}) {
  const [createCoachingSessionStatus, setCreateCoachingSessionStatus] =
    useState("idle");
  const noOfCoachingSessions = coachingSessionsList.length;
  const amount = noOfCoachingSessions * 500; // 500 is kept as hardcoded - should be taken from coach details
  const {
    user: { id: coach_id },
  } = useSelector((state) => state.auth);

  return (
    <section className="pt-2 flex items-center flex-col">
      <div className="border max-w-[420px] rounded w-full px-2 pt-2">
        <header className="py-2 text-center border-b">
          <p className="text-lg font-semibold">
            Amount : <span className="">Rs.{amount} </span>{" "}
          </p>
          <p className="capitalize font-semibold text-gray-500">
            {selectedDate.month} {selectedDate.year}
          </p>
        </header>
        <div className="flex items-center">
          <div className="w-full px-2 pt-4">
            <p className="flex justify-between">
              No. of coaching sessions done: <span>{noOfCoachingSessions}</span>
            </p>
            <p className="flex justify-between">
              Amount/Session: <span>Rs. {500}</span>
            </p>
            <p className="flex justify-between">
              Total amount to be paid: <span> Rs. {amount}</span>
            </p>
            <div className="flex flex-wrap md:flex-nowrap gap-2 my-2 pt-2">
              {createCoachingSessionStatus === "loading" ? (
                <Button type="primary" loading>
                  Loading
                </Button>
              ) : (
                <Button
                  type="primary mx-auto"
                  onClick={() =>
                    handleCreateServiceApproval({
                      data: {
                        fees: 500, // should be taken from coach details
                        total_no_of_sessions: noOfCoachingSessions,
                        generate_for_month: selectedDate.month,
                        generate_for_year: selectedDate.year,
                        coach_id,
                      },
                      setServiceApprovals,
                      setCreateCoachingSessionStatus,
                      setIsCreatingNewApproval,
                      setSelectedDate,
                      setIsServiceApprovalAlreadyExist,
                      setShowCoachingSessions,
                    })
                  }
                >
                  Create Service Approval
                </Button>
              )}

              <Button
                type="secondary"
                onClick={() => setShowCoachingSessions(true)}
              >
                Preview Coaching Sessions
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
