import { createSlice } from "@reduxjs/toolkit";
import { getSlots, reviewSlots } from "../services";

const initialState = {
  slots: [],
  areSlotsConfirmed: false,
  error: null,
};

const reviewSlotsSlice = createSlice({
  name: "review-slot",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(reviewSlots.pending, (state, action) => {})
      .addCase(reviewSlots.fulfilled, (state, action) => {
        state.slots = action.payload.data;
        state.areSlotsConfirmed = true;
      })
      .addCase(reviewSlots.rejected, (state, action) => {})
      .addCase(getSlots.pending, (state, action) => {})
      .addCase(getSlots.fulfilled, (state, action) => {
        state.slots = action.payload.data.filter(
          (slot) => slot.for_learners === false)
      })
      .addCase(getSlots.rejected, (state, action) => {});
  },
});

export default reviewSlotsSlice.reducer;
