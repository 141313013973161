export const getNoOfRequestData = (requestList, coachId) => {
  const res = {
    NEW: 0,
    ACTIVE: 0,
    PAST: 0,
    MISSED: 0,
  };

  if (coachId && requestList.length > 0) {
    requestList.forEach((request) => {
      if (request.assigned_coach.includes(+coachId)) {
        if (
          request.isActive === true &&
          !request.confirmed_coach.includes(+coachId)
        ) {
          res["NEW"] = res["NEW"] + 1;
        }

        if (
          request.isActive === true &&
          request.confirmed_coach.includes(+coachId)
        ) {
          res["ACTIVE"] = res["ACTIVE"] + 1;
        }

        if (
          request.isActive === false &&
          request.confirmed_coach.includes(+coachId)
        ) {
          res["PAST"] = res["PAST"] + 1;
        }

        if (
          request.isActive === false &&
          !request.confirmed_coach.includes(+coachId)
        ) {
          res["MISSED"] = res["MISSED"] + 1;
        }
      }
    });
  }
  return res;
};
