import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSlotRequestData } from "../services";
import {
  confirmSlotsPerRequest,
  getCoachConfirmedSlots,
} from "../services/confirm-slots/confirmSlotsPerRequest";
import { getBookedSessions } from "../services/confirm-slots/getBookedSessions";
import { deleteConfirmSlot } from "../services/edit-confirm-slots/deleteConfirmSlot";

const initialState = {
  availableSlots: [],
  requests: {},
  requestIds: [],
  fetchingSlotRequestData: "idle",
  deleteSlot: "idle",
  updatingSlotStatus: [],
  confirmedSlots: [],
  slotsUpdatedByCurrentRequest: {},
  fetchingConfirmedSlotStatus: "idle",
  bookedSessions: [],
  fetchingBookedSessions: "idle",
};

export const confirmSlotSlice = createSlice({
  name: "confirm-slots",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSlotRequestData.pending, (state, action) => {
        state.fetchingSlotRequestData = "loading";
      })
      .addCase(getSlotRequestData.fulfilled, (state, action) => {
        state.availableSlots = action.payload.slots;
        state.requestIds = Object.keys(action.payload.requests);
        state.requests = action.payload.requests;
        state.fetchingSlotRequestData = "success";
        state.updatingSlotStatus = Object.keys(action.payload.requests).map(
          (id) => ({
            requestId: +id,
            status: "idle",
          })
        );
      })
      .addCase(getSlotRequestData.rejected, (state, action) => {
        state.fetchingSlotRequestData = "failed";
      })
      .addCase(getCoachConfirmedSlots.pending, (state, action) => {
        state.fetchingConfirmedSlotStatus = "loading";
      })
      .addCase(getCoachConfirmedSlots.fulfilled, (state, action) => {
        state.confirmedSlots = action.payload;
        state.fetchingConfirmedSlotStatus = "success";
      })
      .addCase(getCoachConfirmedSlots.rejected, (state, action) => {
        state.fetchingConfirmedSlotStatus = "failed";
      })
      .addCase(confirmSlotsPerRequest.pending, (state, action) => {
        state.updatingSlotStatus = state.updatingSlotStatus.map(
          (slotStatus) => {
            if (slotStatus.requestId === +action.meta.arg.requestId) {
              return { ...slotStatus, status: "loading" };
            } else {
              return { ...slotStatus };
            }
          }
        );
      })
      .addCase(confirmSlotsPerRequest.fulfilled, (state, action) => {
        state.slotsUpdatedByCurrentRequest = {
          ...state.slotsUpdatedByCurrentRequest,
          [action.meta.arg.requestId]: action.meta.arg.slots,
        };
        state.updatingSlotStatus = state.updatingSlotStatus.map(
          (slotStatus) => {
            if (slotStatus.requestId === +action.meta.arg.requestId) {
              return { ...slotStatus, status: "success" };
            } else {
              return { ...slotStatus };
            }
          }
        );

        state.requestIds = state.requestIds.filter(
          (id) => id !== +action.meta.arg.requestId
        );
      })
      .addCase(confirmSlotsPerRequest.rejected, (state, action) => {
        state.updatingSlotStatus = state.updatingSlotStatus.map(
          (slotStatus) => {
            if (slotStatus.requestId === +action.meta.arg.requestId) {
              return { ...slotStatus, status: "failed" };
            } else {
              return { ...slotStatus };
            }
          }
        );
      })
      .addCase(deleteConfirmSlot.pending, (state, action) => {
        state.deleteSlot = "pending";
      })
      .addCase(deleteConfirmSlot.fulfilled, (state, action) => {
        state.deleteSlot = "success";
        toast.success("Slot deleted successfully", { autoClose: 5000 });
      })
      .addCase(deleteConfirmSlot.rejected, (state, action) => {
        state.deleteSlot = "failed";
      })
      .addCase(getBookedSessions.pending, (state, action) => {
        state.fetchingBookedSessions = "pending";
      })
      .addCase(getBookedSessions.fulfilled, (state, action) => {
        state.fetchingBookedSessions = "success";
        state.bookedSessions = action.payload;
      })
      .addCase(getBookedSessions.rejected, (state, action) => {
        state.fetchingBookedSessions = "failed";
      });
  },
});

export default confirmSlotSlice.reducer;
