import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const confirmSlotsPerRequest = createAsyncThunk(
  "confirm-slots-per-request",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CONFIRM_SLOTS_PER_REQUEST}${data.coachId}/${data.requestId}/`,
        data.slots,
        {
          headers: {
            Authorization: `Token ${data.token}`,
          },
        }
      );
      if (res.status === 200) {
        dispatch(data.getCoachConfirmedSlots(data.coachId));
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCoachConfirmedSlots = createAsyncThunk(
  "editCoachConfirmedSlots/getCoachConfirmedSlots",
  async (coachId, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COACH_CONFIRMED_SLOTS}${coachId}/`,
        headers: {
          authorization: `Token ${localStorage.getItem("token_m")}`,
        },
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
