import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authSlice";
import bookSlotReducer from "./reducers/bookSlotsSlice";
import confirmSlotSlice from "./reducers/confirmSlotsSlice";
import reviewSlotReducer from "./reducers/reviewSlotsSlice";
import adminRequestReducer from "./reducers/adminRequestSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    bookSlot: bookSlotReducer,
    reviewSlot: reviewSlotReducer,
    confirmSlots: confirmSlotSlice,
    adminRequest: adminRequestReducer,
  },
});
