import React from "react";
import { sessions } from "../../utils/constants";

const ViewSessions = () => {
  return (
    <main className="py-4 px-8 max-w-[100vw]">
      <section className="flex flex-wrap gap-x-8 justify-between">
        <h2 className="text-lg font-bold shrink-0">
          Your upcoming coaching sessions:{" "}
        </h2>
        <button className="shrink-0 border-b-2">View previous sessions</button>
      </section>
      <div className="overflow-x-scroll">
        <table className="my-4 w-[36rem] md:w-[100%] ">
          <thead className="border-2 ">
            <tr className="text-left">
              <th className="px-2 py-1 border-r-2">Date</th>
              <th className="px-2 py-1 border-r-2">Day</th>
              <th className="px-2 py-1 border-r-2">Session Time</th>
              <th className="px-2 py-1 border-r-2">Learner Name </th>
              <th className="px-2 py-1 border-r-2">Course Name</th>
              <th className="px-2 py-1">Link</th>
            </tr>
          </thead>
          <tbody className="border-2">
            {sessions.map((session) => {
              return (
                <tr className="text-left">
                  <th className="px-2 py-1 border-r-2">{session.date}</th>
                  <th className="px-2 py-1 border-r-2">{session.day}</th>
                  <th className="px-2 py-1 border-r-2">{session.time}</th>
                  <th className="px-2 py-1 border-r-2">
                    {session.learnerName}
                  </th>
                  <th className="px-2 py-1 border-r-2">{session.courseName}</th>
                  <th className="px-2 py-1">
                    <a className="text-sky-400" href={session.link}>
                      Link
                    </a>
                  </th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export { ViewSessions };
