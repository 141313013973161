import { Button } from "antd";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { deleteConfirmSlot } from "../../../redux/services/edit-confirm-slots/deleteConfirmSlot";

const DeleteModal = ({ deleteSlotDetails, setDeleteSlotDetails }) => {
  const { getCoachConfirmedSlots, coach_id, deleteSlot } = deleteSlotDetails;
  const dispatch = useDispatch();
  let date = deleteSlot?.date.split("-");
  date = `${date[2]}-${date[1]}-${date[0]}`;

  // Stop scroll of background page
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <div className="fixed inset-0 z-modal-bg bg-slate-300 opacity-70" />
      <section className="py-1 flex flex-col gap-2 px-8 z-modal w-11/12 sm:w-9/12 lg:w-2/3 xl:w-1/3 border border-zinc-400 rounded fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white">
        <h2 className="mt-2 text-lg font-medium">
          Are to sure you want to delete this slot?
        </h2>
        {/* <p>Coach name: {coachName}</p> */}
        <p>Date: {date}</p>
        <p className="px-4 py-1 w-fit bg-sky-500 rounded">
          {new Date(+deleteSlot.start_time).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}{" "}
          -{" "}
          {new Date(+deleteSlot.end_time).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
        <div className="my-2 flex justify-end">
          <Button
            className="mr-4"
            type="secondary"
            onClick={() => {
              dispatch(
                deleteConfirmSlot({
                  slotId: deleteSlot.id,
                  getCoachConfirmedSlots,
                  coachId: coach_id,
                })
              );
              setDeleteSlotDetails(null);
            }}
          >
            Delete
          </Button>
          <Button type="primary" onClick={() => setDeleteSlotDetails(null)}>
            Cancel
          </Button>
        </div>
      </section>
    </>,
    document.getElementById("portal")
  );
};

export { DeleteModal };
