import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const reviewSlots = createAsyncThunk(
  "review-slot",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CONFIRM_SLOTS}`,
        data.slots,
        {
          headers: {
            Authorization: `Token ${data.token}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
