import { TimePicker } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import moment from "moment";
import { useLocation } from "react-router-dom";

const SlotWrapper = ({ slot, dispatchSlotData, day }) => {
  let { id, start_time, end_time } = slot;
  const { pathname } = useLocation();

  if (pathname === "/review-slots" && start_time && end_time) {
    start_time = moment(+start_time);
    end_time = moment(+end_time);
  }

  return (
    <div className="flex items-center gap-2 md:gap-6">
      <TimePicker
        className="rounded-lg py-2"
        placeholder="Start Time"
        minuteStep={15}
        format="HH:mm"
        use12Hours={true}
        value={start_time}
        onChange={(time) =>
          dispatchSlotData({
            type: "SET_TIME",
            payload: { id, time, day, type: "START_TIME" },
          })
        }
      />{" "}
      -{" "}
      <TimePicker
        className="rounded-lg py-2"
        placeholder="End Time"
        minuteStep={15}
        format="HH:mm"
        use12Hours={true}
        value={end_time}
        onChange={(time) =>
          dispatchSlotData({
            type: "SET_TIME",
            payload: { id, time, day, type: "END_TIME" },
          })
        }
      />
      <button
        onClick={() =>
          dispatchSlotData({ type: "DELETE_SLOT", payload: { day, id } })
        }
        className="text-xl ml-2 text-gray-600"
      >
        <RiDeleteBin6Line />
      </button>
    </div>
  );
};

export { SlotWrapper };
