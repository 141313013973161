import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSlots = createAsyncThunk(
  "get-slots",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SLOTS}`,
        {
          headers: {
            Authorization: `Token ${data.token}`,
          },
        }
      );
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
