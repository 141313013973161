import { Button } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../redux/services";
import Loading from "../../assets/loading-spinner.gif";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resetForgotPasswordState } from "../../redux/reducers/authSlice";

const ForgotPassword = () => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    forgotPasswordSendingEmailStatus,
    forgotPasswordSendingEmailResponseError,
  } = useSelector((state) => state.auth);

  const sendLinkHandler = (e) => {
    e.preventDefault();
    dispatch(
      forgotPassword({
        email,
      })
    );
  };

  useEffect(() => {
    if (forgotPasswordSendingEmailResponseError) {
      setError("Error sending you reset password link, Please try again.");
    }
  }, [forgotPasswordSendingEmailResponseError]);

  if (
    forgotPasswordSendingEmailStatus === "idle" ||
    forgotPasswordSendingEmailStatus === "failed"
  ) {
    return (
      <div className="w-96 mx-auto mt-6">
        <div className="flex flex-col gap-2">
          <h1 className="text-lg text-center font-medium">
            Trouble with logging in?
          </h1>
          <p>
            Enter your email address, and we'll send you a link to get back into
            your account.{" "}
          </p>
          <form onSubmit={sendLinkHandler} className="mt-2">
            <div className="">
              <input
                type="email"
                placeholder="Enter email address"
                className="border w-full rounded border-slat-700 outline-0 p-1 "
                name="email"
                value={email}
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (error) {
                    setError("");
                  }
                }}
              />
            </div>
            <p className="text-red-500 mt-2">{error}</p>
            <Button
              type="primary"
              className="mt-4 rounded block mx-auto"
              htmlType="submit"
            >
              Send Reset Link
            </Button>
          </form>
        </div>
      </div>
    );
  } else if (forgotPasswordSendingEmailStatus === "success") {
    return (
      <>
        <p className="text-lg w-96 mt-10 mx-auto">
          We have sent you an reset link on your email address. Please check
          your email to reset your password.
        </p>
        <Button
          type="primary"
          onClick={() => {
            navigate("/");
            dispatch(resetForgotPasswordState());
          }}
          className="mt-4 rounded block mx-auto"
        >
          Go back to login
        </Button>
      </>
    );
  } else if (forgotPasswordSendingEmailStatus === "loading") {
    <div className="flex items-center justify-center h-full">
      <img className="w-[22rem]" src={Loading} alt="Loading spinner" />
    </div>;
  }
};

export { ForgotPassword };
