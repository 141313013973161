import { Button, Select, Table } from "antd";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookedSessions } from "../../redux/services/confirm-slots/getBookedSessions";
import LoadingSpinner from "../../assets/loading-spinner.gif";
import { setCoachingSessionStatus } from "../../redux/services";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const FIFTEEN_MINUTE_IN_MILLISECONDS = 900000;

const ConfirmedSessions = () => {
  const [sessionsType, setSessionsType] = useState("UPCOMING");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchingBookedSessions, bookedSessions } = useSelector(
    (state) => state.confirmSlots
  );
  const authState = useSelector((state) => state.auth);
  const meet_link_split = authState.user.meet_link.split("/");
  const room_id = meet_link_split[meet_link_split.length - 1];

  const isJoinMeetingButtonDisabled = (sessionDetails) => {
    const formattedDate = sessionDetails.date.split("-").reverse().join("/"); // converts dd-mm-yyyy to yyyy-mm-dd
    const currentTimeStamp = new Date().getTime();

    const sessionStartTimeStamp = new Date(
      `${formattedDate} ${sessionDetails.time}`
    ).getTime();

    const sessionEndTimeStamp = new Date(
      `${formattedDate} ${sessionDetails.endTime}`
    ).getTime();

    if (
      currentTimeStamp >=
      sessionStartTimeStamp - FIFTEEN_MINUTE_IN_MILLISECONDS &&
      currentTimeStamp <= sessionEndTimeStamp
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onChange = (value, sessionDetails) => {
    dispatch(
      setCoachingSessionStatus({
        sessionId: sessionDetails.id,
        status: value,
        getBookedSessions,
        coachId: authState.user.id,
      })
    );
  };

  useEffect(() => {
    dispatch(getBookedSessions(authState.user.id));
  }, [dispatch, authState]);

  const columns = [
    {
      title: "Session Date",
      dataIndex: "date",
      key: "date",
      width: 160,
    },
    {
      title: "Session Time",
      dataIndex: "time",
      key: "time",
      width: 160,
    },
    {
      title: "Learner Name",
      dataIndex: "learnerName",
      key: "learnerName",
      width: 180,
    },
    {
      title: "Learner Email",
      dataIndex: "learnerEmail",
      key: "learnerEmail",
      width: 180,
    },
    {
      title: "Join Session",
      dataIndex: "joinSession",
      key: "joinSession",
      width: 140,
      hidden: sessionsType === "PAST" ? true : false,
      render: (_, sessionDetails) => {
        return (
          <Button
            type="primary"
            disabled={
              isJoinMeetingButtonDisabled(sessionDetails) ? true : false
            }
            onClick={() =>
              navigate(`/join-session/${room_id}`, { state: sessionDetails })
            }
          >
            Join Session
          </Button>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 180,
      hidden: sessionsType === "PAST" ? false : true,
      render: (status, sessionDetails) => {
        return (
          <Select
            className="w-full text-center"
            placeholder="Select"
            optionFilterProp="children"
            defaultValue={status === "null" ? [] : [status]}
            onChange={(value) => onChange(value, sessionDetails)}
          >
            <Option
              selected={status === "Session completed" ? true : false}
              value="Session completed"
            >
              Session completed
            </Option>
            <Option
              selected={status === "Coachee no show" ? true : false}
              value="Coachee no show"
            >
              Coachee no show
            </Option>
          </Select>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const dataSource = [];
  const noOfSessions = {
    PAST: 0,
    UPCOMING: 0,
  };

  const sortedBookedSessions = [...bookedSessions].sort(
    (a, b) => +a.slot.start_time - +b.slot.start_time
  );

  sortedBookedSessions.forEach((curr) => {
    if (
      +curr.slot.end_time > new Date().getTime() &&
      curr.status !== "Session completed"
    ) {
      noOfSessions["UPCOMING"] = noOfSessions["UPCOMING"] + 1;
    } else if (
      +curr.slot.end_time <= new Date().getTime() ||
      curr.status === "Session completed"
    ) {
      noOfSessions["PAST"] = noOfSessions["PAST"] + 1;
    }

    console.log(curr);

    if (sessionsType === "UPCOMING") {
      if (
        +curr.slot.end_time > new Date().getTime() &&
        curr.status !== "Session completed"
      ) {
        dataSource.push({
          learnerName: curr.name,
          learnerEmail: curr.email,
          time: format(new Date(+curr.slot.start_time), "h:mm a"),
          endTime: format(new Date(+curr.slot.end_time), "h:mm a"),
          date: format(new Date(curr.slot.date), "dd-MM-yyyy").toUpperCase(),
          meeting_link: curr.slot.MEETING_LINK,
          id: curr.id,
          status: curr.status,
        });
      }
    } else if (sessionsType === "PAST") {
      if (
        +curr.slot.end_time <= new Date().getTime() ||
        curr.status === "Session completed"
      ) {
        dataSource.push({
          learnerName: curr.name,
          learnerEmail: curr.email,
          time: format(new Date(+curr.slot.start_time), "h:mm a").toUpperCase(),
          endTime: format(new Date(+curr.slot.end_time), "h:mm a"),
          date: format(new Date(curr.slot.date), "dd-MM-yyyy"),
          meeting_link: curr.slot.MEETING_LINK,
          id: curr.id,
          status: curr.status,
        });
      }
    }
  }, []);

  if (fetchingBookedSessions === "loading") {
    return (
      <div className="px-4 mt-6 mb-2 font-semibold text-lg">
        <div className="flex items-center justify-center">
          <img
            className="w-[22rem]"
            src={LoadingSpinner}
            alt="Loading Spinner"
          />
        </div>
      </div>
    );
  } else if (fetchingBookedSessions === "failed") {
    return (
      <div className="px-4 mt-6 mb-2 text-center font-semibold">
        Error in loading sessions. Please try reloading the page.
      </div>
    );
  } else if (fetchingBookedSessions === "success") {
    return (
      <>
        {/* <div className="flex gap-4 ml-4 mt-2 items-center flex-wrap">
          <h3 className="font-medium text-lg">Meet link:</h3>
          <p>{meetLink}</p>
        </div> */}
        <div className="mx-4 mt-6 mb-4 flex gap-2 border-b">
          <div
            onClick={() => setSessionsType("UPCOMING")}
            className={`cursor-pointer pt-2 pb-1 w-[50%] text-center font-semibold ${sessionsType === "UPCOMING"
                ? "bg-tab text-white hover:bg-tab "
                : "bg-tab-bg-inactive hover:bg-tab-bg-inactive-hover"
              }`}
          >
            Upcoming Coaching Sessions ({noOfSessions["UPCOMING"]})
          </div>
          <div
            onClick={() => setSessionsType("PAST")}
            className={`cursor-pointer pt-2 pb-1 w-[50%] text-center font-semibold ${sessionsType === "PAST"
                ? "bg-tab text-white hover:bg-tab "
                : "bg-tab-bg-inactive hover:bg-tab-bg-inactive-hover"
              }`}
          >
            Past Coaching Sessions ({noOfSessions["PAST"]})
          </div>
        </div>
        <Table
          className="m-4 border-2"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            x: 860,
            y: 200,
          }}
        />
      </>
    );
  }
};

export { ConfirmedSessions };
